import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import QueryString from 'query-string';

import DashboardRoute from './DashboardRoute';
import NotFoundPage from './NotFoundPage';

import PasswordProtect from './components/PasswordProtect';
import FirebaseAuthenticated from './components/FirebaseAuthenticated';

import { 
  amalieConfigs, 
  bankersLifeFieldhouseConfigs,
  billsStadiumConfigs,
  bridgestoneArenaConfigs,
  fingerLakesConfigs,
  greatAmericanBallparkConfigs,
  keyBankCenterConfigs,
  lambeauConfigs,
  millerParkConfigs,
  nationWideArenaConfigs, 
  petcoParkConfigs,
  stadiumXConfigs,
  targetFieldConfigs,
  tdGardenConfigs, 
  tiaaBankFieldConfigs,
} from './stadiumConfigs';

import FirebaseService from './services/firebase';

function getFeatureFlags() {
  return QueryString.parse(window.location.search)
}

export default function App(props) {
  const [firebaseInitialized, setFirebaseInitialized] = useState(false);

  useEffect(() => {
    const initFirebase = async () => {
      await FirebaseService.init();
      setFirebaseInitialized(true)
    }

    initFirebase();
  }, []);
  
  const featureFlags = getFeatureFlags();

  // TODO: spinner?
  return (
    <>
    {firebaseInitialized && 
      <FirebaseAuthenticated />
    }
    </>
  )
}
