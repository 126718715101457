import React from 'react';
import { Container } from 'semantic-ui-react';

export default function({
  totalEntryRisk,
  totalEntryTimeIndividual,
  totalEntryTimeStadium,
  totalExitRisk,
  totalExitTimeIndividual,
  totalExitTimeStadium,
  totalTravelRisk,
  totalTravelTimeIndividual,
  totalTravelTimeStadium,
}) {
  return (
    <>
      <table className="covidTable">
        <td>
          <TotalTimeAnalysis
            type={'Entry'}
            totalTimeIndividual={totalEntryTimeIndividual}
            totalTimeStadium={totalEntryTimeStadium}
            totalRisk={totalEntryRisk}
          />
        </td>
        <td></td>
        <td>
          <TotalTimeAnalysis
            type={'Exit'}
            totalTimeIndividual={totalExitTimeIndividual}
            totalTimeStadium={totalExitTimeStadium}
            totalRisk={totalExitRisk}
          />
        </td>
      </table>
      <TotalTimeAnalysis
        type={'Travel'}
        totalTimeIndividual={totalTravelTimeIndividual}
        totalTimeStadium={totalTravelTimeStadium}
        totalRisk={totalTravelRisk}
      />
      </>
  )
}

function TotalTimeAnalysis({
  totalRisk,
  totalTimeIndividual,
  totalTimeStadium,
  type,
}) {
  return (
    <Container>
      <p className="headDesc">Total {type} Analysis</p>
      <table className="covidTable totalTravelAnalysisTable">
        <tr>
          Total {type} Time (Individual):
          <input type="text" value={totalTimeIndividual.toFixed(2)} disabled={true} />
        </tr>
        <tr>
          Total {type} Time (Stadium):
          <input type="text" value={totalTimeStadium.toFixed(2)} disabled={true} />
        </tr>
        <tr>
          Total {type} Risk:
          <input type="text" value={totalRisk.toFixed(2)} disabled={true} />
        </tr>
      </table>
    </Container>
  )
}