import React, { useState } from "react";
import styled from 'styled-components';

import Footer from './components/Footer';
import Concession from './components/Concession';
import GlobalVariables from './components/GlobalVariables';
import SaferStadiaHeader from './components/SaferStadiaHeader';
import SeatedAnalysis from "./components/SeatedAnalysis";
import TravelAnalysis from "./components/TravelAnalysis";
import TimeAnalysis from './components/TimeAnalysis';
import TotalEventAnalysis from './components/TotalEventAnalysis';
import Bathroom from './components/Bathroom';
import AllScenariosChart from './components/AllScenariosChart';

const HideWhenPrint = styled.div`
  @media print {
    & {
      display: none
     }
  }
`

const UpdateConfigButton = styled.button`
  margin-bottom: 16px;
`

const ConfigSelectContainer = styled.div`
  margin-bottom: 8px;
`

const ConfigSelect = styled.select`
  width: 100%;
`


const ConfigSelector = ({ configs, currentConfig, handleConfigSelect }) => {
  const options = configs.map(({id, scenarioName }) => {
    return <option value={id}>{scenarioName}</option>
  })

  return (
    <ConfigSelectContainer>
      <ConfigSelect onChange={(event) => handleConfigSelect(event.target.value)} value={currentConfig.id}>
        {options}
      </ConfigSelect>
    </ConfigSelectContainer>
  )
}


const ClongConfigContainer = styled.div`
  display: flex;
  margin-bottom: 16px;
`
const CloneScenarioName = styled.input`
  width: 60%;
`

const CloneScenarioButton = styled.button`
  width: 40%;
`

const CloneConfig = ({ handleCloneConfig, handleUpdateNewScenarioName, newScenarioName }) => {
  return (
    <ClongConfigContainer>
      <CloneScenarioName value={newScenarioName} onChange={(event) => handleUpdateNewScenarioName(event.target.value)}/>
      <CloneScenarioButton onClick={() => handleCloneConfig(newScenarioName)}>Clone Current Scenario</CloneScenarioButton>
    </ClongConfigContainer>
  )
}

const ConfigOptions = styled.div`
  display: flex;
  justify-content: center;
`

// Don't like this :(
const ConfigOptionsInner = styled.div`
  display: flex;
  flex-direction: column;
  width: 400px;
`

export default ({ 
  adjustedInfectionRate,
  aerosolDropletRisk,
  aerosolRiskEntry,
  aerosolRiskExit,
  aerosolRiskPerMinute,
  ascendingSpeedFactor,
  averageTimeInBathroom,
  bathroomAerosolRisk,
  bathroomAerosolRiskPerMinute,
  bathroomDropletRisk,
  bathroomRisk,
  checkpointEntryEmptyTime,
  checkpointEntryExitTime,
  checkpointEntryRisk,
  checkpointEntryTimeMinutes,
  checkpointEntryTimeSeconds,
  checkpointExitEmptyTime,
  checkpointExitExitTime,
  checkpointExitRisk,
  checkpointExitTimeMinutes,
  checkpointExitTimeSeconds,
  concessionOrderingRisk,
  configs,
  currentConfig,
  descendingSpeedFactor,
  distanceToConcessions,
  eatingAtSeatsRiskUnits,
  eatingTimeAtSeats,
  elevatorArea,
  elevatorCycleTime,
  elevatorDensity,
  elevatorEmptyTime,
  elevatorLoad,
  elevatorRisk,
  elevatorTravelTime,
  elevatorUsingPopulation,
  enclosedVolumeMillions,
  eventDurationMinutes,
  hallwayEmptyTime,
  hallwayExitTime,
  hallwayPeopleWidth,
  hallwayRisk,
  hallwayWidth,
  infectionDetectionRate,
  lessSeatingRiskTime,
  maxPeopleInBathroom,
  netOrderingRisk,
  newScenarioName,
  numberOfElevators,
  numberOfStaircases,
  orderWaitingTime,
  parallelEntryPaths,
  parallelExitPaths,
  pathLength,
  placingOrderTime,
  populationInfectionRate,
  requiredDistance,
  scenarioName,
  seatedAerosolRisk,
  seatedRisk,
  seatedRiskUnits,
  seatPositions,
  skippedSinks,
  skippedUrinals,
  stadiumName,
  stadiumPopulation,
  staircaseDensity,
  staircaseEmptyTimeEntry,
  staircaseEmptyTimeExit,
  staircaseEntryTime,
  staircaseExitTime,
  staircaseHorizontalLength,
  staircaseRiskEntry,
  staircaseRiskExit,
  staircaseUsingPopulation,
  talkingRisk,
  targetColumn,
  targetRow,
  timeToConcessions,
  totalConcessionRisk,
  totalEntryRisk,
  totalEntryTimeIndividual,
  totalEntryTimeStadium,
  totalEventRisk,
  totalExitRisk,
  totalExitTimeIndividual,
  totalExitTimeStadium,
  totalSeatedRisk,
  totalTravelRisk,
  totalTravelTimeIndividual,
  totalTravelTimeStadium,
  totalWalkingTimeToConcessions,
  turnstilesPerEntry,
  turnstilesPerExit,
  vaccinationRate,
  ventilationBathroom,
  ventilationRate,
  virusInfectionDistance,
  volumePerBathroom,
  waitingRisk,
  walkingRisk,
  walkingSpeed,
  wallSpace,
  handleSkippedSinksChange,
  handleSkippedUrinalsChange,
  handleMaxPeopleInBathroomChange,
  handleAverageTimeInBathroomChange,
  handleAscendingSpeedFactorChange,
  handleCheckpointEntryTimeSecondsChange,
  handleCheckpointExitTimeSecondsChange,
  handleCloneConfig,
  handleConfigSelect,
  handleDescendingSpeedFactorChange,
  handleElevatorAreaChange,
  handleElevatorCycleTimeChange,
  handleElevatorLoadChange,
  handleElevatorUsingPopulationChange,
  handleEventDurationMinutesChange,
  handleHallwayWidthChange,
  handleInfectionDetectionRate,
  handleNumberOfElevatorsChange,
  handleNumberOfStaircasesChange,
  handleParallelEntryPathsChange,
  handleParallelExitPathsChange,
  handlePopulationInfectionRate,
  handleRequireDistanceChange,
  handleSeatPositionUpdate,
  handleStadiumPopulationChange,
  handleStaircaseHorizontalLengthChange,
  handleTurnstilesPerEntryChange,
  handleTurnstilesPerExitChange,
  handleVaccinationRateChange,
  handleVirusInfectionDistance,
  handleWalkingSpeedChange,
  handleEatingTimeAtSeats,
  handleDistanceToConcessions,
  handleOrderWaitingTime,
  handlePlacingOrderTime,
  handleUpdateConfig,
  handleScenarioNameChange,
  handleUpdateNewScenarioName,
}) => {
  return (
    <div> 
      <HideWhenPrint>
      <SaferStadiaHeader stadiumName={stadiumName} />

      <GlobalVariables 
        adjustedInfectionRate={adjustedInfectionRate}
        aerosolDropletRisk={aerosolDropletRisk}
        aerosolRiskPerMinute={aerosolRiskPerMinute}
        enclosedVolumeMillions={enclosedVolumeMillions}
        hallwayPeopleWidth={hallwayPeopleWidth}
        hallwayWidth={hallwayWidth}
        infectionDetectionRate={infectionDetectionRate}
        parallelEntryPaths={parallelEntryPaths}
        parallelExitPaths={parallelExitPaths}
        populationInfectionRate={populationInfectionRate}
        requiredDistance={requiredDistance}
        stadiumPopulation={stadiumPopulation}
        vaccinationRate={vaccinationRate}
        ventilationRate={ventilationRate}
        virusInfectionDistance={virusInfectionDistance}
        wallSpace={wallSpace}
        handleHallwayWidthChange={handleHallwayWidthChange}
        handleInfectionDetectionRate={handleInfectionDetectionRate}
        handleParallelEntryPathsChange={handleParallelEntryPathsChange}
        handleParallelExitPathsChange={handleParallelExitPathsChange}
        handlePopulationInfectionRate={handlePopulationInfectionRate}
        handleRequireDistanceChange={handleRequireDistanceChange}
        handleStadiumPopulationChange={handleStadiumPopulationChange}
        handleVaccinationRateChange={handleVaccinationRateChange}
        handleVirusInfectionDistance={handleVirusInfectionDistance}
      />
      <SeatedAnalysis 
        seatPositions={seatPositions}
        targetRow={targetRow}
        targetColumn={targetColumn}
        virusInfectionDistance={virusInfectionDistance}
        eventDurationMinutes={eventDurationMinutes}
        seatedRiskUnits={seatedRiskUnits}
        seatedRisk={seatedRisk}
        seatedAerosolRisk={seatedAerosolRisk}
        totalSeatedRisk={totalSeatedRisk}
        handleEventDurationMinutesChange={handleEventDurationMinutesChange}
        handleSeatPositionUpdate={handleSeatPositionUpdate}
      />
      <TravelAnalysis 
        aerosolRiskEntry={aerosolRiskEntry}
        aerosolRiskExit={aerosolRiskExit}
        ascendingSpeedFactor={ascendingSpeedFactor}
        checkpointEntryEmptyTime={checkpointEntryEmptyTime}
        checkpointEntryExitTime={checkpointEntryExitTime}
        checkpointEntryRisk={checkpointEntryRisk}
        checkpointEntryTimeMinutes={checkpointEntryTimeMinutes}
        checkpointEntryTimeSeconds={checkpointEntryTimeSeconds}
        checkpointExitEmptyTime={checkpointExitEmptyTime}
        checkpointExitExitTime={checkpointExitExitTime}
        checkpointExitRisk={checkpointExitRisk}
        checkpointExitTimeMinutes={checkpointExitTimeMinutes}
        checkpointExitTimeSeconds={checkpointExitTimeSeconds}
        descendingSpeedFactor={descendingSpeedFactor}
        elevatorArea={elevatorArea}
        elevatorCycleTime={elevatorCycleTime}
        elevatorDensity={elevatorDensity}
        elevatorEmptyTime={elevatorEmptyTime}
        elevatorLoad={elevatorLoad}
        elevatorRisk={elevatorRisk}
        elevatorTravelTime={elevatorTravelTime}
        elevatorUsingPopulation={elevatorUsingPopulation}
        hallwayEmptyTime={hallwayEmptyTime}
        hallwayExitTime={hallwayExitTime}
        hallwayRisk={hallwayRisk}
        numberOfElevators={numberOfElevators}
        numberOfStaircases={numberOfStaircases}
        pathLength={pathLength}
        staircaseDensity={staircaseDensity}
        staircaseEmptyTimeEntry={staircaseEmptyTimeEntry}
        staircaseEmptyTimeExit={staircaseEmptyTimeExit}
        staircaseEntryTime={staircaseEntryTime}
        staircaseExitTime={staircaseExitTime}
        staircaseHorizontalLength={staircaseHorizontalLength}
        staircaseRiskEntry={staircaseRiskEntry}
        staircaseRiskExit={staircaseRiskExit}
        staircaseUsingPopulation={staircaseUsingPopulation}
        turnstilesPerEntry={turnstilesPerEntry}
        turnstilesPerExit={turnstilesPerExit}
        walkingSpeed={walkingSpeed}
        handleAscendingSpeedFactorChange={handleAscendingSpeedFactorChange}
        handleCheckpointEntryTimeSecondsChange={handleCheckpointEntryTimeSecondsChange}
        handleCheckpointExitTimeSecondsChange={handleCheckpointExitTimeSecondsChange}
        handleDescendingSpeedFactorChange={handleDescendingSpeedFactorChange}
        handleElevatorAreaChange={handleElevatorAreaChange}
        handleElevatorCycleTimeChange={handleElevatorCycleTimeChange}
        handleElevatorLoadChange={handleElevatorLoadChange}
        handleElevatorUsingPopulationChange={handleElevatorUsingPopulationChange}
        handleNumberOfElevatorsChange={handleNumberOfElevatorsChange}
        handleNumberOfStaircasesChange={handleNumberOfStaircasesChange}
        handleStaircaseHorizontalLengthChange={handleStaircaseHorizontalLengthChange}
        handleTurnstilesPerEntryChange={handleTurnstilesPerEntryChange}
        handleTurnstilesPerExitChange={handleTurnstilesPerExitChange}
        handleWalkingSpeedChange={handleWalkingSpeedChange}
      />
      <TimeAnalysis 
        totalEntryRisk={totalEntryRisk}
        totalEntryTimeIndividual={totalEntryTimeIndividual}
        totalEntryTimeStadium={totalEntryTimeStadium}
        totalExitRisk={totalExitRisk}
        totalExitTimeIndividual={totalExitTimeIndividual}
        totalExitTimeStadium={totalExitTimeStadium}
        totalTravelRisk={totalTravelRisk}
        totalTravelTimeIndividual={totalTravelTimeIndividual}
        totalTravelTimeStadium={totalTravelTimeStadium}
      />
      <hr />
      <Bathroom
        bathroomRisk={bathroomRisk}
        skippedSinks={skippedSinks}
        skippedUrinals={skippedUrinals}
        ventilationBathroom={ventilationBathroom}
        volumePerBathroom={volumePerBathroom}
        maxPeopleInBathroom={maxPeopleInBathroom}
        averageTimeInBathroom={averageTimeInBathroom}
        bathroomAerosolRiskPerMinute={bathroomAerosolRiskPerMinute}
        bathroomAerosolRisk={bathroomAerosolRisk}
        bathroomDropletRisk={bathroomDropletRisk}
        handleSkippedSinksChange={handleSkippedSinksChange}
        handleSkippedUrinalsChange={handleSkippedUrinalsChange}
        handleMaxPeopleInBathroomChange={handleMaxPeopleInBathroomChange}
        handleAverageTimeInBathroomChange={handleAverageTimeInBathroomChange}
      />
      <Concession 
        concessionOrderingRisk={concessionOrderingRisk}
        distanceToConcessions={distanceToConcessions}
        eatingAtSeatsRiskUnits={eatingAtSeatsRiskUnits}
        eatingTimeAtSeats={eatingTimeAtSeats}
        lessSeatingRiskTime={lessSeatingRiskTime}
        netOrderingRisk={netOrderingRisk}
        orderWaitingTime={orderWaitingTime}
        placingOrderTime={placingOrderTime}
        talkingRisk={talkingRisk}
        timeToConcessions={timeToConcessions}
        totalConcessionRisk={totalConcessionRisk}
        totalWalkingTimeToConcessions={totalWalkingTimeToConcessions}
        waitingRisk={waitingRisk}
        walkingRisk={walkingRisk}
        handleEatingTimeAtSeats={handleEatingTimeAtSeats}
        handleDistanceToConcessions={handleDistanceToConcessions}
        handleOrderWaitingTime={handleOrderWaitingTime}
        handlePlacingOrderTime={handlePlacingOrderTime}
      />
      <TotalEventAnalysis
        totalEventRisk={totalEventRisk}
      /> 
      <br/>
      <br/>
      </HideWhenPrint>
      <>
        <br/>
        <hr/>
        <p className="headPage">Scenario Analysis</p>
        <br/>
        <ConfigOptions>
          <ConfigOptionsInner>
            <ConfigSelector currentConfig={currentConfig} configs={configs} handleConfigSelect={handleConfigSelect}/>
            <UpdateConfigButton onClick={handleUpdateConfig}>Update Current Scenario</UpdateConfigButton>
            <CloneConfig 
              handleCloneConfig={handleCloneConfig} 
              handleUpdateNewScenarioName={handleUpdateNewScenarioName} 
              newScenarioName={newScenarioName} />            
          </ConfigOptionsInner>
        </ConfigOptions>
        <br/>
        <br/>
        <AllScenariosChart 
          configs={configs}
          currentConfig={currentConfig}
          stadiumName={stadiumName}/>
      </>
      <br/>
      <Footer />
      <br/>
    </div>    
  )
}
