import firebase from "firebase/app";
import "firebase/database";
import "firebase/auth";
import { firebaseConfig } from "firebaseConfig";

class FirebaseService {
  constructor() {
    this.user = null;
  }

  async init() {
    // should this just be in the constructor?
    return firebase.initializeApp(firebaseConfig); 
  }
  
  async login(userName, password) {
    const firebaseUserName = `billvaleriote+${userName}@gmail.com`;

    try {
      // https://firebase.google.com/docs/auth/web/auth-state-persistence
      await firebase.auth().setPersistence('none')
      
      await firebase.auth().signInWithEmailAndPassword(firebaseUserName, password);
      
      this.user = await firebase.auth().currentUser;

      
      return this;
    } catch (error) {
      console.log('failed to auth', error)
      return this;
    }
  }

  isLoggedIn() {
    return !!this.user;
  } 

  async getConfigs() {
    const user = this.user;

    let configs;
    await firebase.database().ref(`/configs/${user.uid}`).once('value').then((snapshot) => {
      configs = snapshot.val();
    });
    
    // We assume ID's have been set on create  - We could add the value back in here if we wanted to
    return Object.values(configs)
  }

  async updateConfig(config) {
    const user = this.user;

    await firebase.database().ref(`/configs/${user.uid}/${config.id}`).update(config)

    return config;
  }

  async createNewConfig(newConfig) {
    const user = this.user;

    const newConfigKey = firebase.database().ref(`/configs/${user.uid}`).push().key;

    const config = { ...newConfig, id: newConfigKey };

    await firebase.database().ref(`/configs/${user.uid}/${newConfigKey}`).set(config);

    return config;
  }
}

export default new FirebaseService();
