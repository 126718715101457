import React from "react";
import { Container } from "semantic-ui-react";

export default function TotalEventAnalysis(props) {
  return (
    <Container>
      <br/>
      <hr/>
      <p className="headPage">Total Event Analysis</p>
      <table className="covidTable totalEventAnalysisTable">
       <tr>
          Total Risk:
          <input className="totalEventAnalysisTable" type="text" value={props.totalEventRisk.toFixed(2)} disabled={true} />
        </tr>
      </table>
        <br/>
      <p className="headDesc">Comparable Event Risk Units</p>
      <table className="covidTable">

        <tr>
          Restaurant, Full, With Social Distancing (2 hour): 
          <input type="text" value={176} disabled={true} />
        </tr>
        <tr>
          Restaurant, 1/2 Full, With Social Distancing (2 hour): 
          <input type="text" value={79} disabled={true} />
        </tr>
        <tr>
          Stadium, Full, No Entrance/Exit Precautions:
          <input type="text" value={531} disabled={true} />
        </tr>
        <tr>
          Stadium, 2/3 Full, 3 Feet Distancing During Entrance/Exit:
          <input type="text" value={260} disabled={true} />
        </tr>
        <tr>
          Stadium, 1/2 Full, 6 Feet Distancing During Entrance/Exit:
          <input type="text" value={135} disabled={true} />
        </tr>
        <tr>
          3-hour Flight, Full Plane, No Boarding Precautions: 
          <input type="text" value={364} disabled={true} />
        </tr>
        <tr>
          3-hour Flight, Middle Seats Empty, 3 Feet Distancing While Boarding: 
          <input type="text" value={146} disabled={true} />
        </tr>
        <tr>
          3-hour Flight, Half Full with Checkerboard Seating, 6 Feet Distancing While Boarding: 
          <input type="text" value={103} disabled={true} />
        </tr>
        <tr>
          Small Lecture Hall (22 people, 1 hour): 
          <input type="text" value={62} disabled={true} />
        </tr>
        <tr>
          Church, Full (300 people, 1 hour): 
          <input type="text" value={140} disabled={true} />
        </tr>
        <tr>
          Church, 2/3 Full (200 people, 1 hour): 
          <input type="text" value={96} disabled={true} />
        </tr>
        <tr>
          Church, 1/2 Full (150 people, 1 hour): 
          <input type="text" value={69} disabled={true} />
        </tr>
      </table>
    </Container>
  );
}
