import React, { useState } from "react";
import styled from 'styled-components';

const Button = styled.button`
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
`;

const Input = styled.input`
  width: 100%;
  text-align: left;
  padding: 8px;
  margin-bottom: 8px;
  box-sizing: border-box;
`;

const ErrorText = styled.div`
  color: #f98e8a;
  font-size: 14px;
  margin-bottom: 4px;
`

const LoginContainer = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const formWidth = '320px';

const LoginForm = styled.form`
  padding: 24px;
  width: ${formWidth};
`;

const Logo = styled.img`
  width: ${formWidth};
`


/**
 * 
 * two paths, one logging in with firebase, the other with in line password. Does it make sense to use this component? or should creat
 * a login and session service..?
 * is that too much?
 */

export default ({ children, password }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [showError, setShowError] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('')

  const submitPassword = (event) => {
    // prevent default requests to the server
    event.preventDefault();

    if (password === currentPassword) {
      setIsAuthenticated(true);
    } else {
      setCurrentPassword('');
      setShowError(true);
    }
  }

  const DNCLogo = require('../DNC_white.png');

  return (
    <>
      {isAuthenticated ? 
        <div>{children}</div>  
      :
      <LoginContainer>
        {(password !== "stadiumx1") ?
          <Logo src={DNCLogo} alt="Delaware North Logo" />
            :
          <p className="headPage">Omnium AIR Model</p>
        }
        <LoginForm onSubmit={submitPassword}>
          {showError && <ErrorText>Please try again</ErrorText>}
            <Input placeholder="Enter your password" type="password" value={currentPassword} onChange={e => setCurrentPassword(e.target.value)}/>
          <Button>Continue</Button>
        </LoginForm>
      </LoginContainer>
    }
    </>
  )
}
