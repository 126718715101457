import React from "react";
import { Container } from "semantic-ui-react";
import Bathroom from "semantic-ui-react";

export default function SaferStadiaHeader(props) {
  var DNCLogo = require('../DNC_white.png');

  return (
    <Container>
    {(props.stadiumName !== 'Stadium X') ?
      <div className="logoDiv">
        <img class="logo-image" src={DNCLogo} alt="Delaware North Logo" />
      </div>
      :
      <p className="headPage">Omnium AIR Model</p>
    }
      <p className="headPage red">SAFER STADIA CALCULATOR (BETA)</p>
      <p className="headPage">COVID-19 Risk Analysis at {props.stadiumName}</p>
    </Container>
  );
}
