import React from 'react'
import { Container } from "semantic-ui-react";

export default function TravelAnalysis({
  aerosolRiskEntry,
  aerosolRiskExit,
  ascendingSpeedFactor,
  checkpointEntryEmptyTime,
  checkpointEntryExitTime,
  checkpointEntryRisk,
  checkpointEntryTimeMinutes,
  checkpointEntryTimeSeconds,
  checkpointExitEmptyTime,
  checkpointExitExitTime,
  checkpointExitRisk,
  checkpointExitTimeMinutes,
  checkpointExitTimeSeconds,
  descendingSpeedFactor,
  elevatorArea,
  elevatorCycleTime,
  elevatorDensity,
  elevatorEmptyTime,
  elevatorLoad,
  elevatorRisk,
  elevatorTravelTime,
  elevatorUsingPopulation,
  hallwayEmptyTime,
  hallwayExitTime,
  hallwayRisk,
  numberOfElevators,
  numberOfStaircases,
  pathLength,
  staircaseDensity,
  staircaseEmptyTimeEntry,
  staircaseEmptyTimeExit,
  staircaseEntryTime,
  staircaseExitTime,
  staircaseHorizontalLength,
  staircaseRiskEntry,
  staircaseRiskExit,
  staircaseUsingPopulation,
  turnstilesPerEntry,
  turnstilesPerExit,
  walkingSpeed,
  handleAscendingSpeedFactorChange,
  handleCheckpointEntryTimeSecondsChange,
  handleCheckpointExitTimeSecondsChange,
  handleDescendingSpeedFactorChange,
  handleElevatorAreaChange,
  handleElevatorCycleTimeChange,
  handleElevatorLoadChange,
  handleElevatorUsingPopulationChange,
  handleNumberOfElevatorsChange,
  handleNumberOfStaircasesChange,
  handleStaircaseHorizontalLengthChange,
  handleTurnstilesPerEntryChange,
  handleTurnstilesPerExitChange,
  handleWalkingSpeedChange,
}) {
  return (
    <Container>
      <p className="headDesc">Travel Analysis</p>
      <hr />
      <table className="covidTable">
        <td>
          <HorizontalTravel 
           walkingSpeed={walkingSpeed}
           handleWalkingSpeedChange={handleWalkingSpeedChange} 
           pathLength={pathLength}
           hallwayExitTime={hallwayExitTime}
           hallwayEmptyTime={hallwayEmptyTime}
           hallwayRisk={hallwayRisk}
          />
        </td>
        <td></td>
        <td>
          <VerticalTravel 
            ascendingSpeedFactor={ascendingSpeedFactor}
            descendingSpeedFactor={descendingSpeedFactor}
            elevatorArea={elevatorArea}
            elevatorCycleTime={elevatorCycleTime}
            elevatorDensity={elevatorDensity}
            elevatorEmptyTime={elevatorEmptyTime}
            elevatorLoad={elevatorLoad}
            elevatorRisk={elevatorRisk}
            elevatorTravelTime={elevatorTravelTime}
            elevatorUsingPopulation={elevatorUsingPopulation} 
            numberOfElevators={numberOfElevators}
            numberOfStaircases={numberOfStaircases}
            staircaseDensity={staircaseDensity}
            staircaseEmptyTimeEntry={staircaseEmptyTimeEntry}
            staircaseEmptyTimeExit={staircaseEmptyTimeExit}
            staircaseEntryTime={staircaseEntryTime}
            staircaseExitTime={staircaseExitTime}
            staircaseHorizontalLength={staircaseHorizontalLength}
            staircaseRiskEntry={staircaseRiskEntry}
            staircaseRiskExit={staircaseRiskExit}
            staircaseUsingPopulation={staircaseUsingPopulation}
            walkingSpeed={walkingSpeed}
            handleAscendingSpeedFactorChange={handleAscendingSpeedFactorChange}
            handleDescendingSpeedFactorChange={handleDescendingSpeedFactorChange}
            handleElevatorAreaChange={handleElevatorAreaChange}
            handleElevatorCycleTimeChange={handleElevatorCycleTimeChange}
            handleElevatorLoadChange={handleElevatorLoadChange}
            handleElevatorUsingPopulationChange={handleElevatorUsingPopulationChange} 
            handleNumberOfElevatorsChange={handleNumberOfElevatorsChange}
            handleNumberOfStaircasesChange={handleNumberOfStaircasesChange}
            handleStaircaseHorizontalLengthChange={handleStaircaseHorizontalLengthChange}
            handleWalkingSpeedChange={handleWalkingSpeedChange}
          />
        </td>
        </table>
        <table className="covidTable">
        <td>
          <Checkpoint 
            aerosolRisk={aerosolRiskEntry}
            checkpointEmptyTime={checkpointEntryEmptyTime}
            checkpointExitTime={checkpointEntryExitTime}
            checkpointRisk={checkpointEntryRisk}
            checkpointTimeMinutes={checkpointEntryTimeMinutes}
            checkpointTimeSeconds={checkpointEntryTimeSeconds}
            turnstilesPer={turnstilesPerEntry}
            type={"Entry"}
            handleCheckpointTimeSecondsChange={handleCheckpointEntryTimeSecondsChange}
            handleTurnstilesPerChange={handleTurnstilesPerEntryChange}
          />
        </td>
        <td></td>
        <td>
          <Checkpoint 
            aerosolRisk={aerosolRiskExit}
            checkpointEmptyTime={checkpointExitEmptyTime}
            checkpointExitTime={checkpointExitExitTime}
            checkpointRisk={checkpointExitRisk}
            checkpointTimeMinutes={checkpointExitTimeMinutes}
            checkpointTimeSeconds={checkpointExitTimeSeconds}
            turnstilesPer={turnstilesPerExit}
            type={"Exit"}
            handleCheckpointTimeSecondsChange={handleCheckpointExitTimeSecondsChange}
            handleTurnstilesPerChange={handleTurnstilesPerExitChange}
          />
        </td>
      </table>
    </Container>
  );
}

function HorizontalTravel({
  walkingSpeed,
  handleWalkingSpeedChange,
  pathLength,
  hallwayExitTime,
  hallwayEmptyTime,
  hallwayRisk,
}) {
  return (
    <Container>
      <p className="headDesc">Horizontal Travel</p>
      <table className="covidTable">
        <tr>
          Walking Speed (feet/minute): 
          <input type="text" value={walkingSpeed} onChange={handleWalkingSpeedChange} />
        </tr>
        <tr>
          <label>
            Path Length (feet):
            <input type="text" value={pathLength} disabled={true} />
          </label>
        </tr>
        <tr>
          <label>
            Hallway Travel Time (minutes):
            <input type="text" value={hallwayExitTime.toFixed(2)} disabled={true} />
          </label>
        </tr>
        <tr>
          <label>
            Hallway Travel Time Total (minutes):
            <input type="text" value={hallwayEmptyTime.toFixed(2)} disabled={true} />
          </label>
        </tr>
        <tr>
          <label>
            Hallway Risk (risk units):
            <input type="text" value={hallwayRisk.toFixed(2)} disabled={true} />
          </label>
        </tr>
      </table>
    </Container>
  );
}

function VerticalTravelElevator({
  elevatorArea,
  elevatorCycleTime,
  elevatorDensity,
  elevatorEmptyTime,
  elevatorLoad,
  elevatorRisk,
  elevatorTravelTime,
  elevatorUsingPopulation,
  numberOfElevators,
  handleElevatorLoadChange,
}) {
  const weightedElevatorRisk = elevatorRisk * elevatorUsingPopulation;

  return (
    <Container>
      <p className="headDesc">Elevator</p>
      <table className="covidTable">
        <tr>
          Elevator Load:
          <input type="text" 
            value={elevatorLoad} 
            onChange={handleElevatorLoadChange} />
        </tr>
        <tr>
          Elevator Area:
          <input type="text" 
            value={elevatorArea}
            disabled={true} />
        </tr>
        <tr>
          Elevator Density:
          <input type="text" value={elevatorDensity.toFixed(2)} disabled={true} />
        </tr>
        <tr>
          Number of Elevators:
          <input type="text" value={numberOfElevators}
            disabled={true} />
        </tr>
        <tr>
          Elevator Cycle Time:
          <input type="text" 
            value={elevatorCycleTime} 
            disabled={true} />
        </tr>
        <tr>
          Elevator Travel Time:
          <input type="text" value={elevatorTravelTime.toFixed(2)} disabled={true} />
        </tr>
        <tr>
          Elevator Travel Time Total:
          <input type="text" value={elevatorEmptyTime.toFixed(2)} disabled={true} />
        </tr>
        <tr>
          Elevator Risk:
          <input type="text" value={elevatorRisk.toFixed(2)} disabled={true} />
        </tr>
        <tr>
          Weighted Elevator Risk:
          <input type="text" value={weightedElevatorRisk.toFixed(2)} disabled={true} />
        </tr>
      </table>
    </Container>
  );
}

function VerticalTravelStairs({
  numberOfStaircases,
  staircaseDensity,
  staircaseEmptyTimeEntry,
  staircaseEmptyTimeExit,
  staircaseEntryTime,
  staircaseExitTime,
  staircaseHorizontalLength,
  staircaseRiskEntry,
  staircaseRiskExit,
  staircaseUsingPopulation,
  walkingSpeed,
  handleWalkingSpeedChange,
}) {
  const totalStaircaseRisk = staircaseRiskEntry + staircaseRiskExit;

  const weightedStairRisk = totalStaircaseRisk * staircaseUsingPopulation;
  return (
    <Container>
      <p className="headDesc">Stairs</p>
      <table className="covidTable">
        <tr>
          Walking Speed:
          <input type="text" 
            value={walkingSpeed} 
            onChange={handleWalkingSpeedChange}/>
        </tr>
        <tr>
          Staircase Density:
          <input type="text" value={staircaseDensity.toFixed(2)} disabled={true} />
        </tr>
        <tr>
          Number of Staircases:
          <input type="text" 
            value={numberOfStaircases}
            disabled={true} />
        </tr>
        <tr>
          Staircase Horizontal Length:
          <input type="text" 
            value={staircaseHorizontalLength.toFixed(2)}
            disabled={true} />
        </tr>
        <tr>
          Staircase Time (Entry):
          <input type="text" value={staircaseEntryTime.toFixed(2)} disabled={true} />
        </tr>
        <tr>
          Staircase Time (Exit):
          <input type="text" value={staircaseExitTime.toFixed(2)} disabled={true} />
        </tr>
        <tr>
          Staircase Total Time (Entry):
          <input type="text" value={staircaseEmptyTimeEntry.toFixed(2)} disabled={true} />
        </tr>
        <tr>
          Staircase Total Time (Exit):
          <input type="text" value={staircaseEmptyTimeExit.toFixed(2)} disabled={true} />
        </tr>
        <tr>
          Staircase Risk (Entry):
          <input type="text" value={staircaseRiskEntry.toFixed(2)} disabled={true} />
        </tr>
        <tr>
          Staircase Risk (Exit):
          <input type="text" value={staircaseRiskExit.toFixed(2)} disabled={true} />
        </tr>
        <tr>
          Weighted Stair Risk:
          <input type="text" value={weightedStairRisk.toFixed(2)} disabled={true} />
        </tr>
      </table>
    </Container>
  );
}

function VerticalTravel({
  ascendingSpeedFactor,
  descendingSpeedFactor,
  elevatorArea,
  elevatorCycleTime,
  elevatorDensity,
  elevatorEmptyTime,
  elevatorLoad,
  elevatorRisk,
  elevatorTravelTime,
  elevatorUsingPopulation,
  numberOfElevators,
  numberOfStaircases,
  staircaseDensity,
  staircaseEmptyTimeEntry,
  staircaseEmptyTimeExit,
  staircaseEntryTime,
  staircaseExitTime,
  staircaseHorizontalLength,
  staircaseRiskEntry,
  staircaseRiskExit,
  staircaseUsingPopulation,
  walkingSpeed,
  handleAscendingSpeedFactorChange,
  handleDescendingSpeedFactorChange,
  handleElevatorAreaChange,
  handleElevatorCycleTimeChange,
  handleElevatorLoadChange,
  handleElevatorUsingPopulationChange,
  handleNumberOfElevatorsChange,
  handleWalkingSpeedChange,
}) {
  return (
    <Container>
      <table className="covidTable">
        <tr>
          <p className="headDesc">Vertical Travel</p>
            Elevator-Using Population: 
            <input 
              type="text" 
              value={elevatorUsingPopulation}
              onChange={handleElevatorUsingPopulationChange} />
        </tr>
      </table>
      <table className="covidTable">
        <td>
          <VerticalTravelElevator 
            elevatorArea={elevatorArea}
            elevatorCycleTime={elevatorCycleTime}
            elevatorDensity={elevatorDensity}
            elevatorEmptyTime={elevatorEmptyTime}
            elevatorLoad={elevatorLoad}
            elevatorRisk={elevatorRisk}
            elevatorTravelTime={elevatorTravelTime}
            elevatorUsingPopulation={elevatorUsingPopulation}
            numberOfElevators={numberOfElevators}
            handleElevatorAreaChange={handleElevatorAreaChange}
            handleElevatorCycleTimeChange={handleElevatorCycleTimeChange}
            handleElevatorLoadChange={handleElevatorLoadChange}
            handleNumberOfElevatorsChange={handleNumberOfElevatorsChange}
          />
        </td>
        <td>
        </td>
        <td>
          <VerticalTravelStairs
            ascendingSpeedFactor={ascendingSpeedFactor}
            descendingSpeedFactor={descendingSpeedFactor}
            numberOfStaircases={numberOfStaircases}
            staircaseDensity={staircaseDensity}
            staircaseEmptyTimeEntry={staircaseEmptyTimeEntry}
            staircaseEmptyTimeExit={staircaseEmptyTimeExit}
            staircaseEntryTime={staircaseEntryTime}
            staircaseExitTime={staircaseExitTime}
            staircaseHorizontalLength={staircaseHorizontalLength}
            staircaseRiskEntry={staircaseRiskEntry}
            staircaseRiskExit={staircaseRiskExit}
            staircaseUsingPopulation={staircaseUsingPopulation}
            walkingSpeed={walkingSpeed}
            handleAscendingSpeedFactorChange={handleAscendingSpeedFactorChange}
            handleDescendingSpeedFactorChange={handleDescendingSpeedFactorChange}
            handleWalkingSpeedChange={handleWalkingSpeedChange}
          />
        </td>
      </table>
    </Container>
  );
}

function Checkpoint({
  aerosolRisk,
  checkpointEmptyTime,
  checkpointExitTime,
  checkpointRisk,
  checkpointTimeMinutes,
  checkpointTimeSeconds,
  turnstilesPer,
  type,
  handleCheckpointTimeSecondsChange,
  handleTurnstilesPerChange,
}) {
  const totalEntryRisk = aerosolRisk + checkpointRisk;
  return (
    <Container>
      <p className="headDesc">{type} Checkpoint</p>
      <table className="covidTable">
        <tr>
          Checkpoint Time (seconds):
          <input type="text" 
            value={checkpointTimeSeconds}
            onChange={handleCheckpointTimeSecondsChange} />
        </tr>
        <tr>
          Checkpoint Time (minutes):
          <input type="text" value={checkpointTimeMinutes.toFixed(2)} disabled={true} />
        </tr>
        <tr>
          Turnstiles Per {type}:
          <input type="text" 
            value={turnstilesPer} 
            onChange={handleTurnstilesPerChange} />
        </tr>
        <tr>
          Checkpoint {type} Time:
          <input type="text" value={checkpointExitTime.toFixed(2)} disabled={true} />
        </tr>
        <tr>
          Checkpoint Empty Time:
          <input type="text" value={checkpointEmptyTime.toFixed(2)} disabled={true} />
        </tr>
        <tr>
          Checkpoint Risk:
          <input type="text" value={checkpointRisk.toFixed(2)} disabled={true} />
        </tr>
        <tr>
          Aerosol Risk:
          <input type="text" value={aerosolRisk.toFixed(2)} disabled={true} />
        </tr>
        <tr>
          Total {type} Risk:
          <input type="text" value={totalEntryRisk.toFixed(2)} disabled={true} />
        </tr>
      </table>
    </Container>
  )
}