import React  from 'react';
import {
  BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, LabelList, Label, ResponsiveContainer, Text,
} from 'recharts';
import styled from 'styled-components';

import { calculateAllRisksForConfig } from 'calculations';

// Grabbed this from the delaware north website
const FONT_COLOR = 'rgb(65,65,65)';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const StyledBarChart = styled(BarChart)`
  background-color: white;
  color: ${FONT_COLOR};
`

// Some hacks to get the title to show up
// recharts does not have a title component
const ChartTitle = styled.div`
  margin-bottom: -65px;
  color: ${FONT_COLOR};
  font-size: 24px;
  font-weight: bold;
  z-index: 1;
`

// Initial recipe borrowed from https://recharts.org/en-US/examples/StackedBarChart
export default ({ configs, currentConfig, stadiumName }) => {
  const allRiskObjects = configs.map(config => { 
    // We want to use the values that were updated live in the scenario, not what's currently stored in the DB
    
    const configToUse = config.id === currentConfig.id ? currentConfig : config;
    
    return {
      ...calculateAllRisksForConfig(configToUse),
      scenarioName: config.scenarioName
    }
   });

  const chartData = allRiskObjects.map(riskObject => {
    const {
      scenarioName,
      bathroomRisk,
      totalConcessionRisk,
      totalEntryRisk,
      totalExitRisk,
      totalSeatedRisk,
      hallwayRisk,
      totalEventRisk,
      totalStaircaseRisk,
      elevatorRisk,
    } = riskObject;

    return {
      scenarioName,
      Bathrooms: bathroomRisk.toFixed(2),
      Entry: totalEntryRisk.toFixed(2),
      Exit: totalExitRisk.toFixed(2),
      Seated: totalSeatedRisk.toFixed(2),
      Staircase: totalStaircaseRisk.toFixed(2),
      Total: totalEventRisk.toFixed(2),
      Hallway: hallwayRisk.toFixed(2),
      Elevator: elevatorRisk.toFixed(2),
      'F&B': totalConcessionRisk.toFixed(2),
    }
  })

  return (
    <Container>
      <ChartTitle>{stadiumName} Scenario Analysis </ChartTitle>
      {/* TODO use the responsive container for fluid widths, this breaks print mode though */}
      <StyledBarChart
        data={chartData}
        width={900}
        height={800}
        margin={{
          top: 100, right: 50, left: 50, bottom: 60,
        }}
      >
        <XAxis dataKey="scenarioName" tickLine={false} tick={{stroke: FONT_COLOR}}/>
        <YAxis label={{ value: 'Total Risk', angle: -90, position: 'insideLeft'}} />
        <Tooltip labelStyle={{textDecoration: 'underline', marginBottom: '8px'}}/>      
        
        <Legend wrapperStyle={{bottom: "30px"}}/>
        <Bar dataKey="Seated" stackId="a" fill="#00416d"/>
        <Bar dataKey="F&B" stackId="a" fill="#0995cd" />
        <Bar dataKey="Hallway" stackId="a" fill="#007655" />
        <Bar dataKey="Bathrooms" stackId="a" fill="#34a127" />
        <Bar dataKey="Staircase" stackId="a" fill="#fcac21" />
        <Bar dataKey="Entry" stackId="a" fill="#fa4124" />
        <Bar dataKey="Exit" stackId="a" fill="#002841" />
        {/* NOTE: we need the isAnimationActive = false here for the label to show up https://stackoverflow.com/questions/55722306/label-list-is-not-showing-in-recharts */}
        <Bar dataKey="Elevator" stackId="a" fill="#0f537b" isAnimationActive={false}>
          <LabelList dataKey="Total" position="top"/>
        </Bar>
      </StyledBarChart>
      
    </Container>
  );
}
