
// Constants
import {
  SEAT_CONSTANT,
  SEAT_LENGTH_TO_WIDTH_RATIO,
  ROW_SIZE,
  COLUMN_SIZE,
  COHORT_CHAR,
} from './constants';

import generateDefaultSeats from './utils/generateDefaultSeats';

const calculateVaccinationModifier = (vaccinationRate) => 1 - vaccinationRate*(1 - 0.065)

export const calculateDensityRiskFactor = (virusInfectionDistance, requiredDistance, hallwayPeopleWidth) => {
  if (requiredDistance === 0) {
    return 0;
  }

  // first calculate the number of people that can fit into the hallway
  // (which we already calculated in hallwayPeopleWidth)

  const numSpacesInfrontBehind = Math.floor(virusInfectionDistance/requiredDistance);

  // calculate the risk for each spot going across
  // the first doesn't have anyone to the left, and the last
  // doesn't have anyone to the right

  var safetyGuard = 0;
  var totalHallwayRisk = 0;
  for (var p = 0; p < hallwayPeopleWidth; p += 1) {
    //for (var i = 0; i < numSpacesInfrontBehind; i += 1) {
    //for (var i = numSpacesInfrontBehind; i >= (-1 * numSpacesInfrontBehind); i -= 1) {
    for (var i = Math.max(0, p - numSpacesInfrontBehind); i <= Math.min(numSpacesInfrontBehind + p, hallwayPeopleWidth - 1); i += 1) {
      for (var j = numSpacesInfrontBehind; j >= (-1 * numSpacesInfrontBehind); j -= 1) {
        if ( (p === i) && (j === 0) ) {
        } else {
          var personDistance = requiredDistance * Math.sqrt(Math.pow(i-p, 2) + Math.pow(j, 2));
          var hallwayRisk = 1 / Math.pow(personDistance, 2);
          if (personDistance > virusInfectionDistance) {
            hallwayRisk = 0;
          }
          totalHallwayRisk += hallwayRisk;
        }

        if (safetyGuard >= 10000) {
          console.log("Hitting a loop. Exiting loop now");
          break;
        }
        safetyGuard += 1;
      }
    }
  }
  
  return totalHallwayRisk/hallwayPeopleWidth;
}

// TODO: we want to show as '--' if zero
export const calculateHallwayExitTime = (pathLength, walkingSpeed) => {
  if (!walkingSpeed || parseInt(walkingSpeed) === 0) {
    return 0; 
  }
  return pathLength/walkingSpeed;
}

export const calculateHallwayEmptyTime = (requiredDistance, stadiumPopulation, parallelExitPaths, hallwayPeopleWidth, pathLength, walkingSpeed) => {
  // TODO: check for non-zero denominator!
  return (pathLength + stadiumPopulation * requiredDistance / (parallelExitPaths * hallwayPeopleWidth)) / walkingSpeed;
}

// pick the middle seat
const targetRow = Math.floor(ROW_SIZE / 2);
const targetColumn = Math.floor(ROW_SIZE / 2);
 
export const calculateSeatDistance = (i, j) => {
  return SEAT_CONSTANT * Math.sqrt((Math.pow(SEAT_LENGTH_TO_WIDTH_RATIO * (i - targetRow), 2)) + Math.pow(j - targetColumn, 2 ) );
}

export const calculateSeatedRisk = (localVirusInfectionDistance, localEventDurationMinutes, localSeatPositions, vaccinationRate) => {
  const vaccinationModifier = calculateVaccinationModifier(vaccinationRate);
  
  var localSeatedRiskUnits = 0;

  for (var j = 0; j < ROW_SIZE; j += 1) {
    for (var i = 0; i < COLUMN_SIZE; i += 1) {

      var seatDistance = calculateSeatDistance(i, j);
      //var seatDistance = seatConstant * Math.sqrt((Math.pow( seatLengthToWidthRatio * (i - targetRow), 2)) + Math.pow(j - targetColumn, 2 ) );

      // old version has 1/r^2 (r = distance)
      var seatRisk = 1 / Math.pow(seatDistance, 2);

      // TODO: Make this dynamic
      //var seatRisk = 1 / Math.exp(Math.log(2.02 * seatDistance / 3.28)) * 0.100523;
      if (seatDistance > localVirusInfectionDistance) {
        seatRisk = 0;
      }

      // if the target seat, leave empty
      if (i === targetRow && j === targetColumn) {
        seatRisk = 0; 
      }

      // add to risk units if it is NOT a cohort seat
      if (localSeatPositions[i][j] !== COHORT_CHAR) {
        localSeatedRiskUnits += localSeatPositions[i][j] * seatRisk;
      }
    }
  }
  var localSeatedRisk = localEventDurationMinutes * localSeatedRiskUnits * vaccinationModifier;
  return [localSeatedRiskUnits, localSeatedRisk];
}


export const calculateAllRisksForConfig = (config) => {
  const { 
    aerosolDropletRisk,
    ascendingSpeedFactor,
    averageTimeInBathroom,
    checkpointEntryTimeSeconds,
    checkpointExitTimeSeconds,
    defaultSeats,
    descendingSpeedFactor,
    distanceBetweenSinks,
    distanceToConcessions,
    eatingTimeAtSeats,
    elevatorArea,
    elevatorCycleTime,
    elevatorLoad,
    elevatorUsingPopulation,
    enclosedVolumeMillions,
    eventDurationMinutes,
    hallwayWidth,
    infectionDetectionRate,
    isOutdoor,
    maxPeopleInBathroom,
    numberOfElevators,
    numberOfStaircases,
    orderWaitingTime,
    parallelEntryPaths,
    parallelExitPaths,
    passingDistance,
    pathLength,
    placingOrderTime,
    populationInfectionRate,
    requiredDistance,
    seatedRisk,
    seatedRiskUnits,
    sinkWidth,
    skippedSinks,
    skippedUrinals,
    stadiumPopulation,
    staircaseHorizontalLength,
    turnstilesPerEntry,
    turnstilesPerExit,
    vaccinationRate,
    ventilationBathroom,
    ventilationRate,
    virusInfectionDistance,
    walkingSpeed,
  } = config;
  const targetRow = Math.floor(ROW_SIZE / 2);
  const targetColumn = Math.floor(COLUMN_SIZE / 2);
      
  const seatPositions = defaultSeats || generateDefaultSeats(ROW_SIZE, COLUMN_SIZE, targetRow, targetColumn);

  const vaccinationModifier = calculateVaccinationModifier(vaccinationRate);

  // Scenario Variables    

  const adjustedInfectionRate = populationInfectionRate * (1 - infectionDetectionRate)
  // const enclosedVolumeMillions = (isOutdoor) ? 'N/A' : selectedEnclosedVolumeMillions;
  // const ventilationRate = (isOutdoor) ? 'N/A' : selectedVentilationRate;
  const aerosolDropletRiskToUse = (isOutdoor) ? 0 : aerosolDropletRisk;
  const aerosolRiskPerMinute = (isOutdoor) ? 0 : aerosolDropletRiskToUse / ( ventilationRate / stadiumPopulation );  

  // Calculation Values
  // NOTE: doing simple ones inline here, but could see moving all to calculations.js
  const hallwayPeopleWidth = Math.ceil(hallwayWidth/requiredDistance);
  const densityRiskFactor = calculateDensityRiskFactor(virusInfectionDistance, requiredDistance, hallwayPeopleWidth);
  const wallSpace = (hallwayWidth - ((hallwayPeopleWidth - 1) * requiredDistance)) / 2;
  const hallwayExitTime = calculateHallwayExitTime(pathLength, walkingSpeed);
  const hallwayEmptyTime = calculateHallwayEmptyTime(
    requiredDistance, stadiumPopulation, parallelExitPaths, hallwayPeopleWidth, pathLength, walkingSpeed
  );
  const hallwayRisk = (hallwayExitTime * densityRiskFactor) * vaccinationModifier;

  const ventilation = (isOutdoor) ? 0 : ventilationRate / (enclosedVolumeMillions * 1000000);
  const equilibriumAdjustedTime = (isOutdoor) ? 0 : eventDurationMinutes + ( 1 / ventilation) * ( Math.exp( -1 * ventilation * eventDurationMinutes ) - 1 );
  const seatedAerosolRisk = ((isOutdoor) ? 0 : equilibriumAdjustedTime * aerosolRiskPerMinute) * vaccinationModifier;
  const totalSeatedRisk = seatedRisk + seatedAerosolRisk;

  // elevator
  const elevatorDensity = elevatorLoad/elevatorArea;
  const elevatorWaitTime = elevatorCycleTime/2;
  const elevatorTravelTime = elevatorCycleTime/4 + elevatorWaitTime;
  const elevatorEmptyTime = elevatorUsingPopulation * elevatorCycleTime * stadiumPopulation / ( elevatorLoad * numberOfElevators * 49 / 28 );
  const elevatorRisk = (elevatorTravelTime * elevatorDensity * densityRiskFactor) * vaccinationModifier;

  // stairs
  const staircaseUsingPopulation = 1 - elevatorUsingPopulation;

  const staircaseDensity = (numberOfStaircases === 0 ) ? 0 : 1 / Math.pow(requiredDistance, 2);
  const staircaseExitTime = 3.5 * (staircaseHorizontalLength / (walkingSpeed * descendingSpeedFactor));
  const staircaseEntryTime = 3.5 * (staircaseHorizontalLength / (walkingSpeed * ascendingSpeedFactor));
  const staircaseEmptyTimeExit = (numberOfStaircases === 0 ) ? 0 : (stadiumPopulation * staircaseUsingPopulation * requiredDistance / (hallwayPeopleWidth * numberOfStaircases) + staircaseHorizontalLength * 3.5 ) / (walkingSpeed * descendingSpeedFactor);
  const staircaseEmptyTimeEntry = (numberOfStaircases === 0 ) ? 0 : (stadiumPopulation * staircaseUsingPopulation * requiredDistance / (hallwayPeopleWidth * numberOfStaircases) + staircaseHorizontalLength * 3.5 ) / (walkingSpeed * ascendingSpeedFactor);
  const staircaseRiskEntry = (staircaseEntryTime * densityRiskFactor) * vaccinationModifier;
  const staircaseRiskExit = (staircaseExitTime * densityRiskFactor) * vaccinationModifier;

  const totalStaircaseRisk = staircaseRiskEntry + staircaseRiskExit;

  // checkpoint entry
  const checkpointEntryTimeMinutes = checkpointEntryTimeSeconds / 60;
  const checkpointEntryExitTime = checkpointEntryTimeMinutes;
  const checkpointEntryEmptyTime = checkpointEntryTimeMinutes * stadiumPopulation / (parallelEntryPaths * Math.min(turnstilesPerEntry , hallwayPeopleWidth));
  const checkpointEntryRisk = (checkpointEntryExitTime * densityRiskFactor) * vaccinationModifier;

  // checkpoint exit
  const checkpointExitTimeMinutes = checkpointExitTimeSeconds / 60;
  const checkpointExitExitTime = checkpointExitTimeMinutes;
  const checkpointExitEmptyTime = checkpointExitTimeMinutes * stadiumPopulation / (parallelExitPaths * Math.min(turnstilesPerExit , hallwayPeopleWidth));
  const checkpointExitRisk = (checkpointExitExitTime * densityRiskFactor) * vaccinationModifier;

  // totals for Entry, Exit and Travel summations
  const totalEntryTimeIndividual = hallwayExitTime + 
    elevatorUsingPopulation * elevatorTravelTime + 
    staircaseUsingPopulation * staircaseEntryTime +
    checkpointEntryExitTime;
  const totalEntryTimeStadium = Math.max(
      hallwayEmptyTime + 
      Math.max(elevatorEmptyTime, 
               staircaseEmptyTimeEntry),
    checkpointEntryEmptyTime);

  const totalExitTimeIndividual = hallwayExitTime + 
    elevatorUsingPopulation * elevatorTravelTime + 
    staircaseUsingPopulation * staircaseExitTime + 
    checkpointExitExitTime;
  const totalExitTimeStadium = Math.max(
      hallwayEmptyTime + 
      Math.max(elevatorEmptyTime,
               staircaseEmptyTimeExit), 
    checkpointExitEmptyTime);

  // aerosol risks for entry and exit (requires the total entry calculations)
  const aerosolRiskEntry = (aerosolRiskPerMinute * (totalEntryTimeIndividual - checkpointEntryExitTime)) * vaccinationModifier;
  const aerosolRiskExit = (aerosolRiskPerMinute * (totalExitTimeIndividual - checkpointExitExitTime)) * vaccinationModifier;

  // total entry and exit risk
  const totalEntryRisk = hallwayRisk + 
    elevatorUsingPopulation * elevatorRisk + 
    staircaseUsingPopulation * staircaseRiskEntry +
    checkpointEntryRisk + aerosolRiskEntry;
  const totalExitRisk = hallwayRisk + 
    elevatorUsingPopulation * elevatorRisk + 
    staircaseUsingPopulation * staircaseRiskExit + 
    checkpointExitRisk + aerosolRiskExit;

  // total travel analysis
  const totalTravelTimeIndividual = totalEntryTimeIndividual + totalExitTimeIndividual;
  const totalTravelTimeStadium = totalEntryTimeStadium + totalExitTimeStadium;
  const totalTravelRisk = totalEntryRisk + totalExitRisk;
  
  // Bathroom Analysis
  
  const bathroomAerosolRiskPerMinute = maxPeopleInBathroom * aerosolDropletRiskToUse / ventilationBathroom;
  const bathroomAerosolRisk = (bathroomAerosolRiskPerMinute * ( averageTimeInBathroom )) * vaccinationModifier;
  const bathroomPassingRisk = ((maxPeopleInBathroom / 4) / Math.pow(passingDistance, 2)) * vaccinationModifier;

  // sink risk
  
  const sinkSpace = sinkWidth + distanceBetweenSinks;
  const distanceBetweenPeopleAtSinks = sinkSpace + (skippedSinks * sinkSpace);
  const timeAtSink = averageTimeInBathroom / 2;
  const sinkRisk = (2 * timeAtSink / Math.pow(distanceBetweenPeopleAtSinks, 2)) * vaccinationModifier;

  // urinal risk
  const distanceBetweenUrinals = 0;
  const urinalWidth = 1.5;
  const urinalSpace = urinalWidth + distanceBetweenUrinals;
  const distanceBetweenPeopleAtUrinals = urinalSpace + (skippedUrinals * urinalSpace);
  const timeAtUrinal = averageTimeInBathroom / 2;
  const urinalRisk = (2 * timeAtUrinal / Math.pow(distanceBetweenPeopleAtUrinals, 2)) * vaccinationModifier;

  const bathroomDropletRisk = bathroomPassingRisk + sinkRisk + urinalRisk;
  
  const bathroomRisk = bathroomAerosolRisk + bathroomDropletRisk;


  // Concessions Analysis
  
  const timeToConcessions = distanceToConcessions / walkingSpeed;
  const totalWalkingTimeToConcessions = timeToConcessions * 2;
  const walkingRisk = (totalWalkingTimeToConcessions * aerosolRiskPerMinute) * vaccinationModifier ;
  
  const waitingRisk = (orderWaitingTime * aerosolRiskPerMinute) * vaccinationModifier;
  const talkingRisk = (1 * placingOrderTime) * vaccinationModifier;
  const concessionOrderingRisk = walkingRisk + waitingRisk + talkingRisk;
  const lessSeatingRiskTime = (seatedRiskUnits * ( parseInt(orderWaitingTime) + parseInt(totalWalkingTimeToConcessions) )) * vaccinationModifier;
  const netOrderingRisk = concessionOrderingRisk - lessSeatingRiskTime;

  const noMaskModifierReceiving = 2;
  const noMaskModifierTransmitting = 4;
  const additiveModifierForEating = 2.5;
  
  const extraRiskForPersonEating = eatingTimeAtSeats * ( noMaskModifierReceiving + additiveModifierForEating - 1 ) * ( seatedRiskUnits + aerosolRiskPerMinute );
  const extraRiskFromPersonEating = eatingTimeAtSeats * ( noMaskModifierTransmitting - 1 ) * ( seatedRiskUnits + aerosolRiskPerMinute );

  const eatingAtSeatsRiskUnits = (extraRiskForPersonEating + extraRiskFromPersonEating) * vaccinationModifier;

  const totalConcessionRisk = netOrderingRisk + eatingAtSeatsRiskUnits;

  // total event analysis
  const totalEventRisk = totalTravelRisk + totalSeatedRisk + bathroomRisk + totalConcessionRisk;
  
  return {
    adjustedInfectionRate,
    aerosolRiskEntry,
    aerosolRiskExit,
    aerosolRiskPerMinute,
    bathroomAerosolRisk,
    bathroomAerosolRiskPerMinute,
    bathroomDropletRisk,
    bathroomRisk,
    checkpointEntryEmptyTime,
    checkpointEntryExitTime,
    checkpointEntryRisk,
    checkpointEntryTimeMinutes,
    checkpointExitEmptyTime,
    checkpointExitExitTime,
    checkpointExitRisk,
    checkpointExitTimeMinutes,
    concessionOrderingRisk,
    eatingAtSeatsRiskUnits,
    elevatorDensity,
    elevatorEmptyTime,    
    elevatorRisk,
    elevatorTravelTime,
    hallwayEmptyTime,
    hallwayExitTime,
    hallwayPeopleWidth,
    hallwayRisk,
    lessSeatingRiskTime,
    netOrderingRisk,
    seatedAerosolRisk,
    seatPositions,
    staircaseDensity,
    staircaseEmptyTimeEntry,
    staircaseEmptyTimeExit,
    staircaseEntryTime,
    staircaseExitTime,
    staircaseRiskEntry,
    staircaseRiskExit,
    staircaseUsingPopulation,
    talkingRisk,
    targetColumn,
    targetRow,
    timeToConcessions,
    totalConcessionRisk,
    totalEntryRisk,
    totalEntryTimeIndividual,
    totalEntryTimeStadium,
    totalEventRisk,
    totalExitRisk,
    totalExitTimeIndividual,
    totalExitTimeStadium,
    totalSeatedRisk,
    totalStaircaseRisk,
    totalTravelRisk,
    totalTravelTimeIndividual,
    totalTravelTimeStadium,
    totalWalkingTimeToConcessions,
    waitingRisk,
    walkingRisk,
    wallSpace
  }
}