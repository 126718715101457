// TODO: Move these configs over, so we can 
// have everything in one place, and not mess
// up on this single char duplication
const C = 'C';

const defaultSeats = [  
  [0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 1, 1, 1, 1, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0],
  [1, 1, 1, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, C, C, C, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0],
  [1, 1, 1, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 1, 1, 1, 1, 0],
];

const defaultSeatsTDGarden = [  
  [0, 0, 0, 0, 0, 0, 0, 0, 0],
  [1, 1, 0, 0, 1, 1, 1, 1, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0],
  [1, 1, 0, 0, 0, C, C, C, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0],
  [1, 1, 0, 0, 1, 1, 1, 1, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0],
];



export const nationWideArenaConfigs = {
  stadiumName: 'Nationwide Arena',
  isOutdoor: false,

  stadiumPopulation: 5917,
  parallelEntryPaths: 3,
  parallelExitPaths: 3,
  hallwayWidth: 16,
  virusInfectionDistance: 15,

  enclosedVolumeMillions: 17,
  ventilationRate: 360000,
  aerosolDropletRisk: 1,

  eventDurationMinutes: 190,
  elevatorLoad: 8,
  numberOfElevators: 8,
  elevatorArea: 42,
  elevatorCycleTime: 1,

  numberOfStaircases: 6,
  staircaseHorizontalLength: 26,

  pathLength: 400,

  checkpointEntryTimeSeconds: 15.00,
  turnstilesPerEntry: 11,
  checkpointExitTimeSeconds: 3.00,
  turnstilesPerExit: 3.00,

  defaultSeats: defaultSeats, 

  // bathroom
  passingDistance: 1, // in feet
  volumePerBathroom: 9199,
  ventilationBathroom: 1100,
  maxPeopleInBathroom: 25,
  averageTimeInBathroom: 3,
  distanceBetweenSinks: 2,
  sinkWidth: 1.5,

  // concession
  distanceToConcessions: 75,
  orderWaitingTime: 5,
  placingOrderTime: 1,
  eatingTimeAtSeats: 5,
};

export const bridgestoneArenaConfigs = {
  stadiumName: 'Bridgestone Arena',
  isOutdoor: false,

  stadiumPopulation: 4250,
  parallelEntryPaths: 8,
  parallelExitPaths: 8,
  hallwayWidth: 14,
  virusInfectionDistance: 15,

  enclosedVolumeMillions: 25,
  ventilationRate: 100000,
  aerosolDropletRisk: 1,

  eventDurationMinutes: 240,
  elevatorLoad: 5,
  numberOfElevators: 4,
  elevatorArea: 32,
  elevatorCycleTime: 2,

  numberOfStaircases: 21,
  staircaseHorizontalLength: 14,

  pathLength: 400,

  checkpointEntryTimeSeconds: 15,
  turnstilesPerEntry: 4,
  checkpointExitTimeSeconds: 15,
  turnstilesPerExit: 4,

  defaultSeats: defaultSeats, 

  // bathroom
  passingDistance: 1, // in feet
  volumePerBathroom: 8000,
  ventilationBathroom: 1200,
  maxPeopleInBathroom: 15,
  averageTimeInBathroom: 4,
  distanceBetweenSinks: 1.25,
  sinkWidth: 1.33,

  // concession
  distanceToConcessions: 50,
  orderWaitingTime: 3,
  placingOrderTime: 1,
  eatingTimeAtSeats: 5,
};



// TD Garden
export const tdGardenConfigs = {
  stadiumName: 'TD Garden',
  isOutdoor: false,

  requiredDistance: 3,
  stadiumPopulation: 5045,
  parallelEntryPaths: 6,
  parallelExitPaths: 6,
  hallwayWidth: 15,
  virusInfectionDistance: 15,

  enclosedVolumeMillions: 30.5,
  ventilationRate: 480000,
  aerosolDropletRisk: 1,

  eventDurationMinutes: 190,

  elevatorUsingPopulation: .17,
  elevatorLoad: 4,
  numberOfElevators: 8,
  elevatorArea: 74.3,
  elevatorCycleTime: 3,

  numberOfStaircases: 5,
  staircaseHorizontalLength: 16 * 11 / 7,

  checkpointEntryTimeSeconds: 90.00,
  turnstilesPerEntry: 2.00,
  checkpointExitTimeSeconds: 300.00,
  turnstilesPerExit: 3.00,

  defaultSeats: defaultSeatsTDGarden, 

  // bathroom
  passingDistance: 1, // in feet
  volumePerBathroom: 9199,
  ventilationBathroom: 1075,
  maxPeopleInBathroom: 10,
  averageTimeInBathroom: 3,
  distanceBetweenSinks: 2.25,
  sinkWidth: 1.5,
  skippedSinks: 0,
  skippedUrinals: 0,

  // concession
  distanceToConcessions: 25,
  orderWaitingTime: 1,
  placingOrderTime: 1,
  eatingTimeAtSeats: 10,
};

// Target Field
// target is the same as TD Garden for now, except
// for the stadium name
const targetFieldConfigsCopy = JSON.parse(JSON.stringify(tdGardenConfigs));
targetFieldConfigsCopy['stadiumName'] = 'Target Field';
export const targetFieldConfigs = targetFieldConfigsCopy;

// Stadium X
// stadium X is the same as TD Garden for now, except
// for the stadium name
const stadiumXConfigsCopy = JSON.parse(JSON.stringify(tdGardenConfigs));
stadiumXConfigsCopy['stadiumName'] = 'Stadium X';
export const stadiumXConfigs = stadiumXConfigsCopy;

// Bills Stadium
export const billsStadiumConfigs = {
  stadiumName: 'Bills Stadium',
  isOutdoor: true,

  stadiumPopulation: 15438,
  parallelEntryPaths: 57,
  parallelExitPaths: 57,
  hallwayWidth: 22,
  virusInfectionDistance: 15,

  eventDurationMinutes: 180,
  elevatorLoad: 2,
  numberOfElevators: 4,
  elevatorArea: 70,
  elevatorCycleTime: 2,

  numberOfStaircases: 0,
  staircaseHorizontalLength: 0,

  pathLength: 215,

  checkpointEntryTimeSeconds: 15.00,
  turnstilesPerEntry: 5.00,
  checkpointExitTimeSeconds: 15.00,
  turnstilesPerExit: 5.00,

  defaultSeats: defaultSeats, 

  // bathroom
  passingDistance: 1, // in feet
  volumePerBathroom: 9199,
  ventilationBathroom: 1075,
  maxPeopleInBathroom: 4,
  averageTimeInBathroom: 4,

  // concession
  distanceToConcessions: 25,
  orderWaitingTime: 10,
  placingOrderTime: 1,
  eatingTimeAtSeats: 15,
};

// Amalie Arena
export const amalieConfigs = {
  stadiumName: 'Amalie Arena',
  isOutdoor: false,

  stadiumPopulation: 3973,
  parallelEntryPaths: 10,
  parallelExitPaths: 10,
  hallwayWidth: 12,
  virusInfectionDistance: 15,

  enclosedVolumeMillions: 11.2,
  ventilationRate: 480000, // unknown, using td value for now
  aerosolDropletRisk: 1,

  eventDurationMinutes: 180,
  elevatorLoad: 4,
  numberOfElevators: 6,
  elevatorArea: 47,
  elevatorCycleTime: 1,

  numberOfStaircases: 8,
  staircaseHorizontalLength: 16 * 11 / 7, // unknown, using td value for now

  checkpointEntryTimeSeconds: 15.00,
  turnstilesPerEntry: 1.00,
  checkpointExitTimeSeconds: 3.00,
  turnstilesPerExit: 1.00,

  defaultSeats: defaultSeats,

  // keeping all the same as td here, but need these
  // values for each stadium (bathroom and concession)

  // bathroom
  passingDistance: 1, // in feet
  volumePerBathroom: 5513,
  ventilationBathroom: 1075,
  maxPeopleInBathroom: 18,
  averageTimeInBathroom: 4,
  sinkWidth: 1.92,
  distanceBetweenSinks: 2.33,
  skippedSinks: 1,

  // concession
  distanceToConcessions: 120,
  orderWaitingTime: 10,
  placingOrderTime: 1,
  eatingTimeAtSeats: 15,
};

// TIAA Bank Field
export const tiaaBankFieldConfigs = {
  stadiumName: 'TIAA Bank Field',
  isOutdoor: false,

  stadiumPopulation: 18700,
  parallelEntryPaths: 7,
  parallelExitPaths: 7,
  hallwayWidth: 17,
  virusInfectionDistance: 15,

  enclosedVolumeMillions: 7.1,
  ventilationRate: 1533921,
  aerosolDropletRisk: 1,

  eventDurationMinutes: 210,
  elevatorLoad: 5,
  numberOfElevators: 10,
  elevatorArea: 45,
  elevatorCycleTime: .25,

  numberOfStaircases: 7,
  staircaseHorizontalLength: 80,

  checkpointEntryTimeSeconds: 15.00,
  turnstilesPerEntry: 11.00,
  checkpointExitTimeSeconds: 180.00,
  turnstilesPerExit: 11.00,

  defaultSeats: [
    [0, 0, 0, 1, 1, 0, 0, 0, 0],
    [1, 1, 0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 1, 1, 0, 0, 1, 1],
    [1, 1, 0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, C, C, C, 0],
    [0, 0, 0, 0, 0, 0, 0, 0, 0],
    [1, 1, 1, 0, 0, 0, 1, 1, 1],
    [0, 0, 0, 0, 0, 0, 0, 0, 0],
    [1, 1, 1, 0, 0, 0, 0, 0, 0],
  ],

  // bathroom
  passingDistance: 1, // in feet
  volumePerBathroom: 9133,
  ventilationBathroom: 85,
  maxPeopleInBathroom: 20,
  averageTimeInBathroom: 10,

  // concession
  distanceToConcessions: 193,
  orderWaitingTime: 10,
  placingOrderTime: 1,
  eatingTimeAtSeats: 15,
};

export const fingerLakesConfigs = {
  casinoName: 'Finger Lakes Casino',
  numberOfPatrons: 500,
  numberOfWorkers: 40,
  safetyThreshold: 6, // in feet (not really dynamic yet, don't update!)

  movingTimeMinutes: 10,
  passingDistance: 1, // in feet

  airVolume: 1203500,
  ventilation: 23115, // CFM
  aerosolDropletRisk: 1,

  seatedTimeMinutes: 49,

  volumePerBathroom: 9199,
  ventilationBathroom: 1075,
  maxPeopleInBathroom: 4,
  averageTimeInBathroom: 4,

  timeDrinkingMinutes: 5,
  percentageOfPeopleDrinking: 40,
  drinksPerPerson: 1.5,

  timeEatingMinutes: 30,
  numberOfPeopleDiningWithin6Feet: 0,
};

const millerParkDefaultSeats = [
  [1, 1, 1, 1, 1, 1, 1, 1, 1],
  [1, 1, 1, 1, 1, 1, 1, 1, 1],
  [1, 1, 1, 1, 1, 1, 1, 1, 1],
  [1, 1, 1, 1, 1, 1, 1, 1, 1],
  [1, 1, 1, 1, 0, C, C, C, 1],
  [1, 1, 1, 1, 1, 1, 1, 1, 1],
  [1, 1, 1, 1, 1, 1, 1, 1, 1],
  [1, 1, 1, 1, 1, 1, 1, 1, 1],
  [1, 1, 1, 1, 1, 1, 1, 1, 1],
]

export const millerParkConfigs = {
  stadiumName: 'Miller Park',
  isOutdoor: false,

  stadiumPopulation: 42000,
  parallelEntryPaths: 10,
  parallelExitPaths: 10,
  hallwayWidth: 36,
  virusInfectionDistance: 15,

  enclosedVolumeMillions: 36,
  ventilationRate: 1100000,
  aerosolDropletRisk: 1,

  eventDurationMinutes: 195,
  elevatorLoad: 4,
  numberOfElevators: 7,
  elevatorArea: 42,
  elevatorCycleTime: 1,

  numberOfStaircases: 22,
  staircaseHorizontalLength: 43,

  checkpointEntryTimeSeconds: 15.00,
  turnstilesPerEntry: 5.4,
  checkpointExitTimeSeconds: 15.00,
  turnstilesPerExit: 5.4,

  defaultSeats: millerParkDefaultSeats, 

  // bathroom
  passingDistance: 1, // in feet
  volumePerBathroom: 10000,
  ventilationBathroom: 1075,
  maxPeopleInBathroom: 15,
  averageTimeInBathroom: 5,
  distanceBetweenSinks: 1,
  sinkWidth: 1.6,

  // concession
  distanceToConcessions: 150,
  orderWaitingTime: 5,
  placingOrderTime: 1,
  eatingTimeAtSeats: 7,
};

export const keyBankCenterConfigs = {
  // *** Constants / Defaults / Other***
  stadiumName: 'KeyBank Center',
  isOutdoor: false,
  defaultSeats: defaultSeats, 
  virusInfectionDistance: 15,
  passingDistance: 1, // in feet
  aerosolDropletRisk: 1,

  // *** Facility & Event Information ***
  stadiumPopulation: 4195, // 19070 * .22
  enclosedVolumeMillions: 17,
  ventilationRate: 400000,
  eventDurationMinutes: 210,

  // *** Entrance & Egress ***
  parallelEntryPaths: 5,
  parallelExitPaths: 5,
  // 2 and 18 @ main so we averaged
  turnstilesPerEntry: 5.2,
  turnstilesPerExit: 5.2,
  checkpointEntryTimeSeconds: 420.00,
  checkpointExitTimeSeconds: 18.00,
  
  // *** Elevators ***
  numberOfElevators: 4,
  elevatorArea: 41,
  elevatorCycleTime: 3,
  elevatorLoad: 12,

  // *** Hallways & Staircases ***
  hallwayWidth: 12,
  pathLength: 451,
  numberOfStaircases: 15,
  staircaseHorizontalLength: 34,

  // bathroom
  volumePerBathroom: 9199,
  ventilationBathroom: 100000,
  maxPeopleInBathroom: 37,
  averageTimeInBathroom: 5,
  distanceBetweenSinks: .5625,
  sinkWidth: 1.6525,
  skippedSinks: 0,
  skippedUrinals: 0,

  // concession
  distanceToConcessions: 138.37,
  orderWaitingTime: 15,
  placingOrderTime: 1,
  eatingTimeAtSeats: 15,
};

export const petcoParkConfigs = {
  // *** Constants / Defaults / Other***
  stadiumName: "Petco Park",
  isOutdoor: true,
  defaultSeats: defaultSeats, 
  virusInfectionDistance: 15,
  passingDistance: 1, // in feet
  aerosolDropletRisk: 1,

  // *** Facility & Event Information ***
  stadiumPopulation: 8883, // 44,415 * .20
  // enclosedVolumeMillions: null,
  // ventilationRate: null,
  eventDurationMinutes: 186.3,

  // *** Entrance & Egress ***
  parallelEntryPaths: 6,
  parallelExitPaths: 6,
  turnstilesPerEntry: 7.5,
  turnstilesPerExit: 7.5,
  checkpointEntryTimeSeconds: 45,
  checkpointExitTimeSeconds: 15,
  
  // *** Elevators ***
  numberOfElevators: 15,
  elevatorArea: 42,
  elevatorCycleTime: 1,
  elevatorLoad: 4,

  // *** Hallways & Staircases ***
  hallwayWidth: 22,
  pathLength: 615,
  numberOfStaircases: 2,
  staircaseHorizontalLength: 34,

  // *** Restrooms ***
  volumePerBathroom: 7200,
  ventilationBathroom: 1250,
  maxPeopleInBathroom: 20,
  averageTimeInBathroom: 4.2,
  distanceBetweenSinks: 0.8,
  sinkWidth: 1.7,
  skippedSinks: 0,
  skippedUrinals: 0,

  // *** Concessions ***
  distanceToConcessions: 324,
  orderWaitingTime: 5,
  placingOrderTime: 1,
  eatingTimeAtSeats: 10,
}

export const greatAmericanBallparkConfigs = {
  // *** Constants / Defaults / Other***
  stadiumName: "Great American Ballpark",
  isOutdoor: true,
  defaultSeats: defaultSeats, 
  virusInfectionDistance: 15,
  passingDistance: 1, // in feet
  aerosolDropletRisk: 1,

  // *** Facility & Event Information ***
  stadiumPopulation: 11453.5, // 45,814 * .25
  // enclosedVolumeMillions: null,
  // ventilationRate: null,
  eventDurationMinutes: 180,

  // *** Entrance & Egress ***
  parallelEntryPaths: 99,
  parallelExitPaths: 99,
  turnstilesPerEntry: 1,
  turnstilesPerExit: 1,
  checkpointEntryTimeSeconds: 45,
  checkpointExitTimeSeconds: 15,
  
  // *** Elevators ***
  numberOfElevators: 14,
  elevatorArea: 41,
  elevatorCycleTime: 2,
  elevatorLoad: 4,

  // *** Hallways & Staircases ***
  hallwayWidth: 33,
  // This number seems off
  pathLength: 254,
  numberOfStaircases: 116,
  staircaseHorizontalLength: 77,

  // *** Restrooms ***
  volumePerBathroom: 5872,
  ventilationBathroom: 1584,
  maxPeopleInBathroom: 25,
  averageTimeInBathroom: 5,
  // This number seems off
  distanceBetweenSinks: 40,
  sinkWidth: 1.6,
  skippedSinks: 0,
  skippedUrinals: 0,

  // *** Concessions ***
  distanceToConcessions: 100,
  orderWaitingTime: 5,
  placingOrderTime: 1,
  eatingTimeAtSeats: 10,
}


// Lambeau Field
// (use Bill's stadium values for anything we don't have)
export const lambeauConfigs = {
  stadiumName: 'Lambeau Field',
  isOutdoor: true,

  stadiumPopulation: 12216, // 15% of 81,441
  parallelEntryPaths: 7,
  parallelExitPaths: 7,
  hallwayWidth: 25,
  virusInfectionDistance: 15,

  eventDurationMinutes: 180,
  elevatorLoad: 4,
  numberOfElevators: 20,
  elevatorArea: 48,
  elevatorCycleTime: 2,

  numberOfStaircases: 1,
  staircaseHorizontalLength: 30,

  pathLength: 350,

  checkpointEntryTimeSeconds: 15.00,
  turnstilesPerEntry: 10.00,
  checkpointExitTimeSeconds: 15.00,
  turnstilesPerExit: 10.00,

  defaultSeats: defaultSeats, 

  // bathroom
  passingDistance: 1, // in feet
  volumePerBathroom: 9199,
  ventilationBathroom: 1800,
  maxPeopleInBathroom: 15,
  averageTimeInBathroom: 6,
  distanceBetweenSinks: 2.5, // 30"
  sinkWidth: 1.67, // 20"

  // concession
  distanceToConcessions: 120,
  orderWaitingTime: 10,
  placingOrderTime: 1,
  eatingTimeAtSeats: 7,
};


// Bankers Life Fieldhouse
export const bankersLifeFieldhouseConfigs = {
    // *** Constants / Defaults / Other***
    stadiumName: "Bankers Life Fieldhouse",
    isOutdoor: false,
    defaultSeats: defaultSeats, 
    virusInfectionDistance: 15,
    passingDistance: 1, // in feet
    aerosolDropletRisk: 1,
  
    // *** Facility & Event Information ***
    stadiumPopulation: 7400, // 18500 * .40
    enclosedVolumeMillions: 15354600,
    ventilationRate: 154000,
    eventDurationMinutes: 180,
  
    // *** Entrance & Egress ***
    parallelEntryPaths: 4,
    parallelExitPaths: 4,
    turnstilesPerEntry: 7.5,
    turnstilesPerExit: 7.5,
    checkpointEntryTimeSeconds: 10,
    checkpointExitTimeSeconds: 15,
    
    // *** Elevators ***
    numberOfElevators: 4,
    elevatorArea: 94,
    elevatorCycleTime: 1,
    elevatorLoad: 5,
  
    // *** Hallways & Staircases ***
    hallwayWidth: 25,
    pathLength: 400,
    numberOfStaircases: 3,
    staircaseHorizontalLength: 49,
  
    // *** Restrooms ***
    volumePerBathroom: 4450,
    ventilationBathroom: 875,
    maxPeopleInBathroom: 10,
    averageTimeInBathroom: 4,
    distanceBetweenSinks: 0.79, // 9.5"
    sinkWidth: 1.5,
    skippedSinks: 0,
    skippedUrinals: 0,
  
    // *** Concessions ***
    distanceToConcessions: 77,
    orderWaitingTime: 3,
    placingOrderTime: 1,
    eatingTimeAtSeats: 10,
};
