import React from 'react';

export default ({
  adjustedInfectionRate,
  aerosolDropletRisk,
  aerosolRiskPerMinute,
  enclosedVolumeMillions,
  hallwayPeopleWidth,
  hallwayWidth,
  infectionDetectionRate,
  parallelEntryPaths,
  parallelExitPaths,
  populationInfectionRate,
  requiredDistance,
  stadiumPopulation,
  vaccinationRate,
  ventilationRate,
  virusInfectionDistance,
  wallSpace,
  handleHallwayWidthChange,
  handleInfectionDetectionRate,
  handleParallelEntryPathsChange,
  handleParallelExitPathsChange,
  handlePopulationInfectionRate,
  handleRequireDistanceChange,
  handleStadiumPopulationChange,
  handleVaccinationRateChange,
  handleVirusInfectionDistance,
}) => {
  return (
    <>
      <p className="headDesc">Global Variables</p>
      <table className="covidTable">
        <td>
          <tr>
            Required Social Distance:
            <input type="text" value={6} disabled={true}/>
          </tr>
          <tr>
            Minimum Passing Distance (feet): 
            <label>
              <input type="number" value={requiredDistance} onChange={handleRequireDistanceChange} />
            </label>
          </tr>
          <tr>
            Stadium Population:
              <input type="text" value={stadiumPopulation} onChange={handleStadiumPopulationChange} />
          </tr>
          <tr>
            Parallel Entry Paths: 
              <input type="text" value={parallelEntryPaths} onChange={handleParallelEntryPathsChange} />
          </tr>
          <tr>
            Parallel Exit Paths: 
              <input type="text" value={parallelExitPaths} onChange={handleParallelExitPathsChange} />
          </tr>
          <tr>
            Hallway Width (feet): 
            <input type="text" value={hallwayWidth} onChange={handleHallwayWidthChange} />
          </tr>
          <tr>
            Hallway Width (people):
              <input type="text" value={hallwayPeopleWidth} disabled={true}/>
          </tr>
          
          <tr>
            Wall Space (feet): 
              <input type="text" value={wallSpace.toFixed(2)} disabled={true}/>
          </tr>
        </td>
        <td>
          <tr>
            Population Infection Rate: 
            <input type="text" value={populationInfectionRate} onChange={handlePopulationInfectionRate} />
          </tr>
          <tr>
            Infection Detection Rate: 
            <input type="text" value={infectionDetectionRate} onChange={handleInfectionDetectionRate} />
          </tr>
          <tr>
            Adjusted Infection Rate:
              <input type="text" value={adjustedInfectionRate.toFixed(4)} disabled={true} />
          </tr>
          <tr>
            Enclosed Volume (MM cubic feet):
              <input type="text" value={enclosedVolumeMillions} disabled={true} />
          </tr>
          <tr>
            Ventilation Rate (cfm):
            <input type="text" value={ventilationRate} disabled={true} />
          </tr>
          <tr>
            Aerosol:Droplet Risk:
            <input type="text" value={aerosolDropletRisk} disabled={true}/>
          </tr>
          <tr>
            Aerosol Risk Per Minute:
            <input type="text" value={aerosolRiskPerMinute.toFixed(2)} disabled={true}/>
          </tr>
          <tr>
             Virus Infection Distance (feet): 
            <input type="text" value={virusInfectionDistance} onChange={handleVirusInfectionDistance}/>
          </tr>
          <tr>
             Vaccination Rate: 
            <input type="text" value={vaccinationRate} onChange={handleVaccinationRateChange}/>
          </tr>
        </td>
      </table>
    </>
  )
}