import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  font-style: italic;
  text-align: center;
`

export default () => {
  return (
    <Container>
      Powered by <a href="https://www.stemmler.tech/"> Stemmler Technologies </a>
    </Container>
  )
}