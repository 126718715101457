import React from 'react';
import { Container } from "semantic-ui-react";

import { COHORT_CHAR, COLUMN_SIZE, ROW_SIZE } from '../constants';
import { calculateSeatDistance } from '../calculations';

const renderSquares = (rowSize, columnSize, seatPositions, targetRow, targetColumn, virusInfectionDistance, handleSeatPositionUpdate) => {
  var numSeatsFilled = 0;
  var table = [];
  for (let j = 0; j < rowSize; j += 1) {
    let row = [];
    for (let i = 0; i < columnSize; i += 1) {
      let key = "{i}-{j}";
      let className = "seatCell";
      let disabled = false;
      let value = seatPositions[i][j];

      // if value > 0, is modeled seat, or a cohort seat, then we include
      if ((value > 0) || 
          (value === COHORT_CHAR) || 
          (i === targetRow && j === targetColumn)) {
        numSeatsFilled += 1;
      }

      let seatDistance = calculateSeatDistance(i, j);

      if (i === targetRow && j === targetColumn) {
        className += " modeledSeat";
        disabled = true;
        value = "*";
      }
      else if (seatPositions[i][j] === COHORT_CHAR) {
        className += " modeledSeat";
      }
      // grey out seats that are beyond the virus infection distance
      // since they have no risk adding to the total
      else if (seatDistance > virusInfectionDistance) {
        className += " grey";
      }
      else if (seatPositions[i][j] === 0) {
        className += " green";
      }
      else if (seatPositions[i][j] === 1) {
        className += " yellow";
      }
      else if (seatPositions[i][j] === 2) {
        className += " orange";
      }
      else {
        className += " red";
      }
      row.push(
        <tr key="{i}-{j}">
          <input 
            className={className} 
            type="text" 
            value={value} 
            disabled={disabled} 
            onClick={(event) => handleSeatPositionUpdate(i, j, key, event, true)}
            onChange={(event) => handleSeatPositionUpdate(i, j, key, event, false)} />
        </tr>
      );
    }
    table.push(row);
  }
  var numTotalSeats = columnSize * rowSize;
  var numSeatsPopulated = (numSeatsFilled/numTotalSeats)*100;

  return (
    <table className='covidTable seatTable'>
      {table.map((column, index) => (
        <td key={index} className='seatColumn'> {column} </td>))
      }
      <tr>
        <td colspan={columnSize} >
          <i>{numSeatsPopulated.toFixed(1)}% seats populated
          </i>
        </td>
      </tr>
   </table>
  );
}

export default function SeatedAnalysis({
  seatPositions, 
  targetRow, 
  targetColumn, 
  virusInfectionDistance,
  eventDurationMinutes,
  seatedRiskUnits,
  seatedRisk,
  seatedAerosolRisk,
  totalSeatedRisk,
  handleEventDurationMinutesChange,
  handleSeatPositionUpdate,
}) {
  const squares = renderSquares(ROW_SIZE, COLUMN_SIZE, seatPositions, targetRow, targetColumn, virusInfectionDistance, handleSeatPositionUpdate);
  
  return (
    <Container>
      <p className="headDesc">Seated Analysis</p>
      <hr />
      <table className="covidTable">
        <tr>
          Event Duration (minutes):
          <input type="text" 
            value={eventDurationMinutes} 
            onChange={handleEventDurationMinutesChange} />
        </tr>
        <tr>
          Droplet Risk Per Minute: 
          <input type="text" value={seatedRiskUnits.toFixed(2)} disabled={true} />
        </tr>
        <tr>
          Droplet Seated Risk: 
          <input type="text" value={seatedRisk.toFixed(2)} disabled={true} />
        </tr>
        <tr>
          Seated Aerosol Risk: 
          <input type="text" value={seatedAerosolRisk.toFixed(2)} disabled={true} />
        </tr>
        <tr>
          Total Seated Risk: 
          <input type="text" value={totalSeatedRisk.toFixed(2)} disabled={true} />
        </tr>
      </table>
      <table className="covidTable">
        <td>
        {squares}
        </td>

        <td>
          <table className='covidTable legend'>
            <td>
            <u className='covidTable legend legendTitle'>
              Legend:
            </u>
            <tr className='legendTitle'>
              * - modeled seat
            </tr>
            <tr className='legendTitle'>
              {COHORT_CHAR} - trusted cohort seat
            </tr>
            <tr className='greenBackground'>
              0 - empty seat
            </tr>
            <tr className='yellowBackground'>
              1 - occupied seat with standard risk
            </tr>
            <tr className='orangeBackground'>
              2 - occupied seat with 2x standard risk
            </tr>
            <tr className='redBackground'>
              N - occupied seat with Nx standard risk, N > 2
            </tr>
            <tr className='greyBackground'>
              <i>
                grey background indicates the seat is outside of the virus infection zone
              </i>
            </tr>
            </td>
          </table>
        </td>
      </table>
    </Container>
  );
}