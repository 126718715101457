import React from 'react';

export default function({
  concessionOrderingRisk,
  distanceToConcessions,
  eatingAtSeatsRiskUnits,
  eatingTimeAtSeats,
  lessSeatingRiskTime,
  netOrderingRisk,
  orderWaitingTime,
  placingOrderTime,
  talkingRisk,
  timeToConcessions,
  totalConcessionRisk,
  totalWalkingTimeToConcessions,
  waitingRisk,
  walkingRisk,
  handleEatingTimeAtSeats,
  handleDistanceToConcessions,
  handleOrderWaitingTime,
  handlePlacingOrderTime,
}) {
 return (
  <>
    <p className="headDesc">Concession Analysis</p>
    <table className="covidTable">
    <td>
    <table className="covidTable">
      <tr>
        Distance to Concessions (feet):
        <input type="text" value={distanceToConcessions} onChange={handleDistanceToConcessions} />
      </tr>
      <tr>
        Time to Concessions:
        <input type="text" value={timeToConcessions.toFixed(2)} disabled={true} />
      </tr>
      <tr>
        Total Walking Time to Concessions:
        <input type="text" value={totalWalkingTimeToConcessions.toFixed(2)} disabled={true} />
      </tr>
      <tr>
        Walking Risk:
        <input type="text" value={walkingRisk.toFixed(2)} disabled={true} />
      </tr>
    </table>
    </td>
    <td>
    <table>
      <tr>
        Order Waiting Time:
        <input type="text" value={orderWaitingTime} onChange={handleOrderWaitingTime} />
      </tr>
      <tr>
        Waiting Risk:
        <input type="text" value={waitingRisk.toFixed(2)} disabled={true} />
      </tr>
      <tr>
        Placing Order Time:
        <input type="text" value={placingOrderTime} onChange={handlePlacingOrderTime} />
      </tr>
      <tr>
        Talking Risk:
        <input type="text" value={talkingRisk.toFixed(2)} disabled={true} />
      </tr>
    </table>
    </td>
    </table>

    <table className="covidTable">
      <tr>
        Concesssion Ordering Risk:
        <input type="text" value={concessionOrderingRisk.toFixed(2)} disabled={true} />
      </tr>
      <tr>
        Less Seating Risk Time:
        <input type="text" value={lessSeatingRiskTime.toFixed()} disabled={true} />
      </tr>
      <tr className="spaceUnder">
        Net Ordering Risk:
        <input type="text" value={netOrderingRisk.toFixed(2)} disabled={true} />
      </tr>
    </table>
    <table className="covidTable">
      <tr>
        Eating Time at Seats:
        <input type="text" value={eatingTimeAtSeats} onChange={handleEatingTimeAtSeats} />
      </tr>
      <tr>
        Additional Risk from Eating:
        <input type="text" value={eatingAtSeatsRiskUnits.toFixed(2)} disabled={true} />
      </tr>
    </table>
    <table className="covidTable">
      <tr>
        Total Concession Risk:
        <input type="text" value={totalConcessionRisk.toFixed(2)} disabled={true} />
      </tr>
    </table>
  </>
 ) 
}