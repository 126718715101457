import firebase from "firebase/app";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: "AIzaSyCohgzA6zaURiZdqVH_P2U-5JS5-hL6iJ0",
  authDomain: "stadia-9770d.firebaseapp.com",
  databaseURL: "https://stadia-9770d.firebaseio.com",
  projectId: "stadia-9770d",
  storageBucket: "stadia-9770d.appspot.com",
  messagingSenderId: "845197960762",
  appId: "1:845197960762:web:5ad6ee845454491338f703",
  measurementId: "G-XM3BE96JK8"
};
