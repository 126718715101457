import React, { useState } from "react";
import { Container } from "semantic-ui-react";

export default function Bathroom({
  bathroomRisk,
  skippedSinks,
  skippedUrinals,
  ventilationBathroom,
  volumePerBathroom,
  maxPeopleInBathroom,
  averageTimeInBathroom,
  bathroomAerosolRiskPerMinute,
  bathroomAerosolRisk,
  bathroomDropletRisk,
  handleSkippedSinksChange,
  handleSkippedUrinalsChange,
  handleMaxPeopleInBathroomChange,
  handleAverageTimeInBathroomChange,
}) {
  return (
    <Container>
      <p className="headDesc">Bathroom Analysis</p>
      <table className="covidTable">
        <tr>
          Volume Per Bathroom:
          <input type="text" value={volumePerBathroom} disabled={true} />
        </tr>
        <tr>
          Ventilation Bathroom (CFM):
          <input type="text" value={ventilationBathroom} disabled={true} />
        </tr>
        <tr>
          Max People in Bathroom:
          <input type="text" value={maxPeopleInBathroom}
            onChange={(event) => handleMaxPeopleInBathroomChange(parseInt(event.target.value) || 0)} />
        </tr>
        <tr>
          Skipped Sinks:
          <input type="text" value={skippedSinks}
            onChange={(event) => handleSkippedSinksChange(parseInt(event.target.value) || 0)} />
        </tr>
        <tr>
          Skipped Urinals:
          <input type="text" value={skippedUrinals}
            onChange={(event) => handleSkippedUrinalsChange(parseInt(event.target.value) || 0)} />
        </tr>
        <tr>
          Average Time in Bathroom (minutes):
          <input type="text" value={averageTimeInBathroom}
            onChange={(event) => handleAverageTimeInBathroomChange(parseInt(event.target.value) || 0)} />
        </tr>
        <tr>
          Bathroom Aerosol Risk Per Minute:
          <input type="text" value={bathroomAerosolRiskPerMinute.toFixed(2)} disabled={true} />
        </tr>
        <tr>
          Bathroom Aerosol Risk:
          <input type="text" value={bathroomAerosolRisk.toFixed(2)} disabled={true} />
        </tr>

        <tr>
          Bathroom Droplet Risk:
          <input type="text" value={bathroomDropletRisk.toFixed(2)} disabled={true} />
        </tr>
        <tr>
          Bathroom Risk:
          <input type="text" value={bathroomRisk.toFixed(2)} disabled={true} />
        </tr>
      </table>
    </Container>
);
}
