import React, { useState, useEffect, useReducer } from "react";
import { Container } from "semantic-ui-react";

import './Dashboard.css';
import DashboardView from './DashboardView';
import { 
  calculateSeatedRisk,
  calculateAllRisksForConfig,
 } from './calculations';
import { COHORT_CHAR } from './constants';
import FirebaseService from 'services/firebase';

// BUGS TO FIX (Fixed 7/27/2020)
// Changing Virus Infection Distance (feet):
// -- updates the map, but doesn’t update total seated risk
// Entry checkpoint Time seconds (and same for Exit):
// - redirects

// Question:
// - added parallel entry paths
//
// TODO:
// - validation of inputs!

// DONE:
// - change exit to travel analysis
// - add checkpoint entry
// - have total travel analysis and add new checkpoint entry to those sums
// - total travel risk = horizontal + vertical travel + entry and exit risk
// - total event risk = travel risk + seated risk
// stretch goal:
// - grey out the boxes if beyond the distance
//
// sunday:
// - update the math behind the scenes

// 2 decimal places for input fields

// input variables:
//
// GLOBALS
// - stadium population
// - required distance
// - hallway width (feet?)
// - total stadium seats
// - seat width
// - parallel exit paths
// - safety threshold (NEW!)
//
// - walking speed
// - elevator using population
// - elevator load
// - elevator area
// - number of elevators
// - elevator cycle time
// - descending speed factor
// - ascending speed factor (NEW!)
// - number of staircases
// - staircase horizontal length
// - checkpoint time
// - turnstiles per exit
// - event duration
//

// to add back later:
//        <tr>
//          Infection Adjusted Total Risk:
//          <input className="totalEventAnalysisTable" type="text" value={props.infectionAdjustedTotalRisk.toFixed(2)} disabled={true} />
//        </tr>



/**
 * OLD TODOS?
  // - stadium population
  // - required distance
  // - hallway width (feet?)
  // - total stadium seats (NEW?)  TODO
  // - seat width (NEW?) TODO
  // - parallel exit paths
  // - safety threshold (NEW!) TODO
  //
 */

const configDefaults = {
  ascendingSpeedFactor: .378,
  
  descendingSpeedFactor: .498,
  elevatorUsingPopulation: .15,
  infectionDetectionRate: .25,
  populationInfectionRate: .0020,
  seatedRisk: 0,
  seatedRiskUnits: 0,
  walkingSpeed: 100,

  // Putting these here instead of || with 0 in line
  skippedSinks: 0,
  skippedUrinals: 0,
  distanceBetweenSinks: 1,
  sinkWidth: 1.58,

  requiredDistance: 3,

  // Default to what TD Garden pathlength
  pathLength: 600,

  vaccinationRate: 0,
}

// TODO move reducers out of this file
const NEW_CONFIG_ACTION = 'NewConfigAction';
const UPDATE_CONFIG_VALUE_ACTION = 'UpdateConfigValueAction';

function currentConfigReducer(state, action) {
  switch (action.type) {
    case NEW_CONFIG_ACTION:
      // TODO merge in with default values IE required distance
      return { config: { ...configDefaults, ...action.config } }
    // Should this allow for multiple actions?
    case UPDATE_CONFIG_VALUE_ACTION:
      const newConfig = { ...state.config };
      newConfig[action.key] = action.value;
      return { config: newConfig };
    default:
      return state;
  }
}

const ADD_NEW_CONFIG = 'configs/AddNewConfig';
const UPDATE_CONFIG = 'configs/UpdateConfig';

function configsReducer(state, action) {
  switch (action.type) {
    case ADD_NEW_CONFIG:
      const newConfigs = [...state.configs, action.config]

      return {
        ...state,
        configs: newConfigs
      }
    case UPDATE_CONFIG:
      const { configs } = state;
      
      const indexOfUpdatedConfig = configs.map(({ id }) => id).indexOf(action.config.id);
      
      const clonedConfigs = [...configs];
      clonedConfigs[indexOfUpdatedConfig] = action.config
      
      return {
        ...state,
        configs: clonedConfigs
      }
    default:
      return state;
  }
}

export default function Dashboard({ configs }) {
  const [configsState, configsDispatch] = useReducer(configsReducer, { configs });
  const [currentConfigState, currentConfigDispatch] = useReducer(currentConfigReducer, { config: { ...configDefaults, ...configsState.configs[0] }})

  const handleConfigSelect = function(selectedConfigId) {
    const selectedConfig = configsState.configs.find(config => config.id === selectedConfigId);

    currentConfigDispatch({
      type: NEW_CONFIG_ACTION,
      config: selectedConfig
    })

    setNewScenarioName(`${selectedConfig.scenarioName} (Copy)`)
  }

  const { 
    enclosedVolumeMillions: selectedEnclosedVolumeMillions,
    ventilationRate: selectedVentilationRate,
    pathLength,
    requiredDistance,
    distanceToConcessions,
    eatingTimeAtSeats,
    orderWaitingTime,
    placingOrderTime,
    aerosolDropletRisk,
    ascendingSpeedFactor,
    averageTimeInBathroom,
    checkpointEntryTimeSeconds,
    checkpointExitTimeSeconds,
    defaultSeats,
    descendingSpeedFactor,
    distanceBetweenSinks,
    elevatorArea,
    elevatorCycleTime,
    elevatorLoad,
    elevatorUsingPopulation,
    eventDurationMinutes,
    hallwayWidth,
    infectionDetectionRate,
    isOutdoor,
    maxPeopleInBathroom,
    numberOfElevators,
    numberOfStaircases,
    parallelEntryPaths,
    parallelExitPaths,
    passingDistance,
    populationInfectionRate,
    scenarioName,
    seatedRisk,
    seatedRiskUnits,
    sinkWidth,
    skippedSinks,
    skippedUrinals,
    stadiumName,
    stadiumPopulation,
    staircaseHorizontalLength,
    turnstilesPerEntry,
    turnstilesPerExit,
    vaccinationRate,
    ventilationBathroom,
    virusInfectionDistance,
    volumePerBathroom,
    walkingSpeed,
  } = currentConfigState.config;

  const [newScenarioName, setNewScenarioName] = useState(`${currentConfigState.config.scenarioName} (Copy)`)

  const handleCloneConfig = async (scenarioName) => {
    // TODO: handle async with cloning here
    const config = await FirebaseService.createNewConfig({ ...currentConfigState.config, scenarioName });
    
    configsDispatch({
      type: ADD_NEW_CONFIG,
      config
    })
    
    currentConfigDispatch({
      type: NEW_CONFIG_ACTION,
      config
    })

    setNewScenarioName(`${config.scenarioName} (Copy)`)
  }

  const handleUpdateConfig = async () => {
    const config = await FirebaseService.updateConfig(currentConfigState.config);

    configsDispatch({
      type: UPDATE_CONFIG,
      config
    })

    setNewScenarioName(`${config.scenarioName} (Copy)`)
  }

  // Scenario Variables    
  // const adjustedInfectionRate = populationInfectionRate * (1 - infectionDetectionRate)
  const enclosedVolumeMillions = (isOutdoor) ? 'N/A' : selectedEnclosedVolumeMillions;
  const ventilationRate = (isOutdoor) ? 'N/A' : selectedVentilationRate;
  const aerosolDropletRiskToUse = (isOutdoor) ? 0 : aerosolDropletRisk;
  // const aerosolRiskPerMinute = (isOutdoor) ? 0 : aerosolDropletRiskToUse / ( ventilationRate / stadiumPopulation );  


  // Handlers
  const handleRequireDistanceChange = (event) => {
    let distance = parseInt(event.target.value) || 1;

    if (distance > 6) {
      distance = 6
    } else if (distance < 1) {
      distance = 1;
    }

    currentConfigDispatch({
      type: UPDATE_CONFIG_VALUE_ACTION,
      key: 'requiredDistance',
      value: distance
    }) 
  }
  
  const handleVirusInfectionDistance = (event) => {
    var newVirusInfectionDistance = parseInt(event.target.value) || 0;

    currentConfigDispatch({
      type: UPDATE_CONFIG_VALUE_ACTION,
      key: 'virusInfectionDistance',
      value: event.target.value
    }) 

    // trigger re-calculating the total seated risk
    updateSeatedRisk(newVirusInfectionDistance, eventDurationMinutes, seatPositions, vaccinationRate);
  }
    
  const updateSeatedRisk = (virusInfectionDistance, eventDurationMinutes, updatedSeatPositions, vaccinationRate) => {
    var [newSeatedRiskUnits, newSeatedRisk] = calculateSeatedRisk(virusInfectionDistance, eventDurationMinutes, updatedSeatPositions, vaccinationRate);

    currentConfigDispatch({
      type: UPDATE_CONFIG_VALUE_ACTION,
      key: 'seatPositions',
      value: updatedSeatPositions
    });

    currentConfigDispatch({
      type: UPDATE_CONFIG_VALUE_ACTION,
      key: 'seatedRisk',
      value: newSeatedRisk
    });

    currentConfigDispatch({
      type: UPDATE_CONFIG_VALUE_ACTION,
      key: 'seatedRiskUnits',
      value: newSeatedRiskUnits
    });
  }

  const isCohortChar = (input) => {
    if (!input) {
      return false;
    }

    // if there is one character, check for COHORT_CHAR
    if (input.length === 1) {
      return (input.toLowerCase() === COHORT_CHAR.toLowerCase());
    }

    // if more than one character, check for COHORT_CHAR existence
    return input.toLowerCase().includes(COHORT_CHAR.toLowerCase());
  }

  // Handlers
  const handleSeatPositionUpdate = (i, j, key, event, isClick) => {
    console.log("Handling seat update to ", event.target.value, " is click?", isClick);
    let newValue = isCohortChar(event.target.value) ? COHORT_CHAR : (parseInt(event.target.value) || 0);
    console.log("newValue", newValue);
    /*
    if (newValue !== 0 && newValue !== 1 && newValue !== 2) {
      return;
    }
    */
    let updatedSeatPositions = seatPositions;
    updatedSeatPositions[i][j] = newValue;

    updateSeatedRisk(virusInfectionDistance, eventDurationMinutes, updatedSeatPositions, vaccinationRate);
  }

  const handleEventDurationMinutesChange = (event) => {
    var newEventDurationMinutes = parseInt(event.target.value) || 0;
    var [newSeatedRiskUnits, newSeatedRisk] = calculateSeatedRisk(virusInfectionDistance, newEventDurationMinutes, seatPositions, vaccinationRate);
    
    currentConfigDispatch({
      type: UPDATE_CONFIG_VALUE_ACTION,
      key: 'eventDurationMinutes',
      value: newEventDurationMinutes
    });

    currentConfigDispatch({
      type: UPDATE_CONFIG_VALUE_ACTION,
      key: 'seatedRisk',
      value: newSeatedRisk
    });

    currentConfigDispatch({
      type: UPDATE_CONFIG_VALUE_ACTION,
      key: 'seatedRiskUnits',
      value: newSeatedRiskUnits
    });
  }

  // TODO: update bathroom component handlerls to take event so we can use handleUpdateConfigValueForKey 
  const handleMaxPeopleInBathroomChange = (value) => {
    currentConfigDispatch({
      type: UPDATE_CONFIG_VALUE_ACTION,
      key: 'maxPeopleInBathroom',
      value: value
    });
  }

  const handleAverageTimeInBathroomChange = (value) => {
    currentConfigDispatch({
      type: UPDATE_CONFIG_VALUE_ACTION,
      key: 'averageTimeInBathroom',
      value: value
    });
  }
  
  const handleSkippedSinksChange = (value) => {
    currentConfigDispatch({
      type: UPDATE_CONFIG_VALUE_ACTION,
      key: 'skippedSinks',
      value: value
    });
  }

  const handleSkippedUrinalsChange = (value) => {
    currentConfigDispatch({
      type: UPDATE_CONFIG_VALUE_ACTION,
      key: 'skippedUrinals',
      value: value
    });
  }

  // generates a function to handle a standard config value update
  const handleUpdateConfigValueForKey = (configValueKey) => {
    return (event) => {
      currentConfigDispatch({
        type: UPDATE_CONFIG_VALUE_ACTION,
        key: configValueKey,
        value: event.target.value
      }) 
    }
  }

  const {
    adjustedInfectionRate,
    aerosolRiskEntry,
    aerosolRiskExit,
    aerosolRiskPerMinute,
    bathroomAerosolRisk,
    bathroomAerosolRiskPerMinute,
    bathroomDropletRisk,
    bathroomRisk,
    checkpointEntryEmptyTime,
    checkpointEntryExitTime,
    checkpointEntryRisk,
    checkpointEntryTimeMinutes,
    checkpointExitEmptyTime,
    checkpointExitExitTime,
    checkpointExitRisk,
    checkpointExitTimeMinutes,
    concessionOrderingRisk,
    eatingAtSeatsRiskUnits,
    elevatorDensity,
    elevatorEmptyTime,    
    elevatorRisk,
    elevatorTravelTime,
    hallwayEmptyTime,
    hallwayExitTime,
    hallwayPeopleWidth,
    hallwayRisk,
    lessSeatingRiskTime,
    netOrderingRisk,
    seatedAerosolRisk,
    seatPositions,
    staircaseDensity,
    staircaseEmptyTimeEntry,
    staircaseEmptyTimeExit,
    staircaseEntryTime,
    staircaseExitTime,
    staircaseRiskEntry,
    staircaseRiskExit,
    staircaseUsingPopulation,
    talkingRisk,
    targetColumn,
    targetRow,
    timeToConcessions,
    totalConcessionRisk,
    totalEntryRisk,
    totalEntryTimeIndividual,
    totalEntryTimeStadium,
    totalEventRisk,
    totalExitRisk,
    totalExitTimeIndividual,
    totalExitTimeStadium,
    totalSeatedRisk,
    totalTravelRisk,
    totalTravelTimeIndividual,
    totalTravelTimeStadium,
    totalWalkingTimeToConcessions,
    waitingRisk,
    walkingRisk,
    wallSpace
  } = calculateAllRisksForConfig(currentConfigState.config);

  // at the end, after everything has been declared or a config has been updated
  useEffect(() => {
    var [newSeatedRiskUnits, newSeatedRisk] = calculateSeatedRisk(virusInfectionDistance, eventDurationMinutes, seatPositions, vaccinationRate);

    currentConfigDispatch({
      type: UPDATE_CONFIG_VALUE_ACTION,
      key: 'seatedRisk',
      value: newSeatedRisk
    });

    currentConfigDispatch({
      type: UPDATE_CONFIG_VALUE_ACTION,
      key: 'seatedRiskUnits',
      value: newSeatedRiskUnits
    });
  }, [virusInfectionDistance,eventDurationMinutes, seatPositions, vaccinationRate]);

  return (
    <Container>
      <DashboardView 
        adjustedInfectionRate={adjustedInfectionRate}
        aerosolDropletRisk={aerosolDropletRiskToUse}
        aerosolRiskEntry={aerosolRiskEntry}
        aerosolRiskExit={aerosolRiskExit}
        aerosolRiskPerMinute={aerosolRiskPerMinute}
        ascendingSpeedFactor={ascendingSpeedFactor}
        averageTimeInBathroom={averageTimeInBathroom}
        averageTimeInBathroom={averageTimeInBathroom}
        bathroomAerosolRisk={bathroomAerosolRisk}
        bathroomAerosolRiskPerMinute={bathroomAerosolRiskPerMinute}
        bathroomDropletRisk={bathroomDropletRisk}
        bathroomRisk={bathroomRisk}
        checkpointEntryEmptyTime={checkpointEntryEmptyTime}
        checkpointEntryExitTime={checkpointEntryExitTime}
        checkpointEntryRisk={checkpointEntryRisk}
        checkpointEntryTimeMinutes={checkpointEntryTimeMinutes}
        checkpointEntryTimeSeconds={checkpointEntryTimeSeconds}
        checkpointExitEmptyTime={checkpointExitEmptyTime}
        checkpointExitExitTime={checkpointExitExitTime}
        checkpointExitRisk={checkpointExitRisk}
        checkpointExitTimeMinutes={checkpointExitTimeMinutes}
        checkpointExitTimeSeconds={checkpointExitTimeSeconds}
        concessionOrderingRisk={concessionOrderingRisk}
        configs={configsState.configs}
        currentConfig={currentConfigState.config}
        descendingSpeedFactor={descendingSpeedFactor}
        distanceBetweenSinks={distanceBetweenSinks}
        distanceToConcessions={distanceToConcessions}
        eatingAtSeatsRiskUnits={eatingAtSeatsRiskUnits}
        eatingTimeAtSeats={eatingTimeAtSeats}
        elevatorArea={elevatorArea}
        elevatorCycleTime={elevatorCycleTime}
        elevatorDensity={elevatorDensity}
        elevatorEmptyTime={elevatorEmptyTime}
        elevatorLoad={elevatorLoad}
        elevatorRisk={elevatorRisk}
        elevatorTravelTime={elevatorTravelTime}
        elevatorUsingPopulation={elevatorUsingPopulation}
        elevatorUsingPopulation={elevatorUsingPopulation}
        enclosedVolumeMillions={enclosedVolumeMillions}
        eventDurationMinutes={eventDurationMinutes}
        hallwayEmptyTime={hallwayEmptyTime}
        hallwayExitTime={hallwayExitTime}
        hallwayPeopleWidth={hallwayPeopleWidth}
        hallwayRisk={hallwayRisk}
        hallwayWidth={hallwayWidth}
        infectionDetectionRate={infectionDetectionRate}
        lessSeatingRiskTime={lessSeatingRiskTime}
        maxPeopleInBathroom={maxPeopleInBathroom}
        maxPeopleInBathroom={maxPeopleInBathroom}
        netOrderingRisk={netOrderingRisk}
        newScenarioName={newScenarioName}
        numberOfElevators={numberOfElevators}
        numberOfStaircases={numberOfStaircases}
        orderWaitingTime={orderWaitingTime}
        parallelEntryPaths={parallelEntryPaths}
        parallelExitPaths={parallelExitPaths}
        passingDistance={passingDistance}
        pathLength={pathLength}
        placingOrderTime={placingOrderTime}
        populationInfectionRate={populationInfectionRate}
        requiredDistance={requiredDistance}
        scenarioName={scenarioName}
        seatedAerosolRisk={seatedAerosolRisk}
        seatedRisk={seatedRisk}
        seatedRiskUnits={seatedRiskUnits}
        seatPositions={seatPositions}
        sinkWidth={sinkWidth}
        skippedSinks={skippedSinks}
        skippedUrinals={skippedUrinals}
        stadiumName={stadiumName}
        stadiumPopulation={stadiumPopulation}
        staircaseDensity={staircaseDensity}
        staircaseEmptyTimeEntry={staircaseEmptyTimeEntry}
        staircaseEmptyTimeExit={staircaseEmptyTimeExit}
        staircaseEntryTime={staircaseEntryTime}
        staircaseExitTime={staircaseExitTime}
        staircaseHorizontalLength={staircaseHorizontalLength}
        staircaseRiskEntry={staircaseRiskEntry}
        staircaseRiskExit={staircaseRiskExit}
        staircaseUsingPopulation={staircaseUsingPopulation}
        talkingRisk={talkingRisk}
        targetColumn={targetColumn}
        targetRow={targetRow}
        timeToConcessions={timeToConcessions}
        totalConcessionRisk={totalConcessionRisk}
        totalEntryRisk={totalEntryRisk}
        totalEntryTimeIndividual={totalEntryTimeIndividual}
        totalEntryTimeStadium={totalEntryTimeStadium}
        totalEventRisk={totalEventRisk}
        totalExitRisk={totalExitRisk}
        totalExitTimeIndividual={totalExitTimeIndividual}
        totalExitTimeStadium={totalExitTimeStadium}
        totalSeatedRisk={totalSeatedRisk}
        totalTravelRisk={totalTravelRisk}
        totalTravelTimeIndividual={totalTravelTimeIndividual}
        totalTravelTimeStadium={totalTravelTimeStadium}
        totalWalkingTimeToConcessions={totalWalkingTimeToConcessions}
        turnstilesPerEntry={turnstilesPerEntry}
        turnstilesPerExit={turnstilesPerExit}
        vaccinationRate={vaccinationRate}
        ventilationBathroom={ventilationBathroom}
        ventilationRate={ventilationRate}
        virusInfectionDistance={virusInfectionDistance}
        volumePerBathroom={volumePerBathroom}
        volumePerBathroom={volumePerBathroom}
        waitingRisk={waitingRisk}
        walkingRisk={walkingRisk}
        walkingSpeed={walkingSpeed}
        wallSpace={wallSpace}

        handleAscendingSpeedFactorChange={handleUpdateConfigValueForKey('ascendingSpeedFactor')}
        handleCheckpointEntryTimeSecondsChange={handleUpdateConfigValueForKey('checkpointEntryTimeSeconds')}
        handleCheckpointExitTimeSecondsChange={handleUpdateConfigValueForKey('checkpointExitTimeSeconds')}
        handleDescendingSpeedFactorChange={handleUpdateConfigValueForKey('descendingSpeedFactor')}
        handleDistanceToConcessions={handleUpdateConfigValueForKey('distanceToConcessions')}
        handleEatingTimeAtSeats={handleUpdateConfigValueForKey('eatingTimeAtSeats')}
        handleElevatorAreaChange={handleUpdateConfigValueForKey('elevatorArea')}
        handleElevatorCycleTimeChange={handleUpdateConfigValueForKey('elevatorCycleTime')}
        handleElevatorLoadChange={handleUpdateConfigValueForKey('elevatorLoad')}
        handleElevatorUsingPopulationChange={handleUpdateConfigValueForKey('elevatorUsingPopulation')}
        handleEventDurationMinutesChange={handleEventDurationMinutesChange}
        handleHallwayWidthChange={handleUpdateConfigValueForKey('hallwayWidth')}
        handleInfectionDetectionRate={handleUpdateConfigValueForKey('infectionDetectionRate')}
        handleNumberOfElevatorsChange={handleUpdateConfigValueForKey('numberOfElevators')}
        handleNumberOfStaircasesChange={handleUpdateConfigValueForKey('numberOfStaircases')}
        handleOrderWaitingTime={handleUpdateConfigValueForKey('orderWaitingTime')}
        handleParallelEntryPathsChange={handleUpdateConfigValueForKey('parallelEntryPaths')}
        handleParallelExitPathsChange={handleUpdateConfigValueForKey('parallelExitPaths')}
        handlePlacingOrderTime={handleUpdateConfigValueForKey('placingOrderTime')}
        handlePopulationInfectionRate={handleUpdateConfigValueForKey('populationInfectionRate')}
        handleStadiumPopulationChange={handleUpdateConfigValueForKey('stadiumPopulation')}
        handleStaircaseHorizontalLengthChange={handleUpdateConfigValueForKey('staircaseHorizontalLength')}
        handleTurnstilesPerEntryChange={handleUpdateConfigValueForKey('turnstilesPerEntry')}
        handleTurnstilesPerExitChange={handleUpdateConfigValueForKey('turnstilesPerExit')}
        handleWalkingSpeedChange={handleUpdateConfigValueForKey('walkingSpeed')}
        handleScenarioNameChange={handleUpdateConfigValueForKey('scenarioName')}
        handleVaccinationRateChange={handleUpdateConfigValueForKey('vaccinationRate')}
        handleVirusInfectionDistance={handleVirusInfectionDistance}
        handleRequireDistanceChange={handleRequireDistanceChange}
        handleSeatPositionUpdate={handleSeatPositionUpdate}
        handleCloneConfig={handleCloneConfig}
        handleConfigSelect={handleConfigSelect}
        handleUpdateConfig={handleUpdateConfig}        
        handleSkippedUrinalsChange={handleSkippedUrinalsChange}
        handleSkippedSinksChange={handleSkippedSinksChange}
        handleMaxPeopleInBathroomChange={handleMaxPeopleInBathroomChange}
        handleAverageTimeInBathroomChange={handleAverageTimeInBathroomChange}
        handleUpdateNewScenarioName={setNewScenarioName}
      />
    </Container>
  );
}
