export default (rowSize, columnSize, targetRow, targetColumn) => {
  var localSeatPositions = [];

  for (var j = 0; j < rowSize; j += 1) {
    var rowPositions = [];
    for (var i = 0; i < columnSize; i += 1) {
      var value = 1;

      // if the target seat, leave empty
      if (i === targetRow && j === targetColumn) {
        value = 0;
      }

      // if 1 away from the target seat, leave empty
      else if (Math.abs(i - targetRow) <= 1 && (Math.abs(j - targetColumn) <= 1)) {
        value = 0;
      }

      rowPositions.push(value);
    }
    localSeatPositions.push(rowPositions);
  }
  console.log("localSeatPositions:", localSeatPositions);
  return localSeatPositions;
};
