import React, { useLayoutEffect, useEffect, useState } from "react";
import styled from 'styled-components';

import DashboardRoute from 'DashboardRoute';

import { 
  tiaaBankFieldConfigs,
} from '../stadiumConfigs';

import FirebaseService from 'services/firebase';


const Button = styled.button`
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
`;

const Input = styled.input`
  width: 100%;
  text-align: left;
  padding: 8px;
  margin-bottom: 8px;
  box-sizing: border-box;
`;

const ErrorText = styled.div`
  color: #f98e8a;
  font-size: 14px;
  margin-bottom: 4px;
`

const LoginContainer = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const formWidth = '320px';

const LoginForm = styled.form`
  padding: 24px;
  width: ${formWidth};
`;

const Logo = styled.img`
  width: ${formWidth};
`

const DNCLogo = require('../DNC_white.png');

const TDGardens = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [configs, setConfigs] = useState(null);
  
  const login = async (userName, password) => {
    await FirebaseService.login(userName, password);
    
    if (FirebaseService.isLoggedIn()) {
      setIsAuthenticated(true);
      const configs = await FirebaseService.getConfigs();
      setConfigs(configs);
    }
  } 
  
  useLayoutEffect(() => {
    login('tdgarden', 'tdgarden1');
  }, []);

  return (
    <>
    {(isAuthenticated && configs) ? 
      <DashboardRoute configs={configs} />        
      :
      <LoginContainer>    
        <Logo src={DNCLogo} alt="Delaware North Logo" />
        {/* Spinner */}
      </LoginContainer>
    }
    </>
  )
}

export default function() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [showError, setShowError] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('')

  const [configs, setConfigs] = useState(null);

  const submitPassword = async (event) => {
    // prevent default requests to the server
    event.preventDefault();

    //  Get user name from url
    const pathName = window.location.pathname;
    // remove the '/'
    const userName = pathName.slice(1);

    await FirebaseService.login(userName, currentPassword);
    
    if (FirebaseService.isLoggedIn()) {
      setIsAuthenticated(true);
      const configs = await FirebaseService.getConfigs();
      setConfigs(configs);
    } else {
      setCurrentPassword('');
      setShowError(true);
    }
  }

  //  Get user name from url
  const pathName = window.location.pathname;
  // remove the '/'
  const userName = pathName.slice(1);
  
  if (userName === '') {
    return <TDGardens />
  } else {
    return (
      <>
    {isAuthenticated && configs ? 
      <DashboardRoute configs={configs} />
      :
    <LoginContainer>    
      {/* TODO handle the stadium X case */}
      <Logo src={DNCLogo} alt="Delaware North Logo" />
      <LoginForm onSubmit={submitPassword}>
        {showError && <ErrorText>Please try again</ErrorText>}
          <Input placeholder="Enter your password" type="password" value={currentPassword} onChange={e => setCurrentPassword(e.target.value)}/>
        <Button>Continue</Button>
      </LoginForm>
    </LoginContainer>
  }
  </>
  )
  }
}
